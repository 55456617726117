import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  amount: 0,
  loading: false,
  expectedReward: 0,
  noUpdateButtonFlg: false,
  closeIndicate: false,
};

export const handoverSlice = createSlice({
  name: 'handover',
  initialState,
  reducers: {
    formToConfirm: (state, action) => {
      return Object.assign({}, state, { amount: action.payload });
    },
    setLoading: (state, action) => {
      return Object.assign({}, state, { loading: action.payload });
    },
    setExpectedReward: (state, action) => {
      return Object.assign({}, state, { expectedReward: action.payload });
    },
    setNoUpdateButtonFlg: (state, action) => {
      return Object.assign({}, state, { noUpdateButtonFlg: action.payload });
    },
    setCloseIndicate: (state, action) => {
      return Object.assign({}, state, { closeIndicate: action.payload });
    },
  },
});

export const {
  formToConfirm,
  setLoading,
  setExpectedReward,
  setNoUpdateButtonFlg,
  setCloseIndicate,
} = handoverSlice.actions;
