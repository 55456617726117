import React from 'react';
import { styled } from 'twin.macro';

type Props = {
  size: 'lg' | 'md' | 'sm' | 'xs';
};
export const Spacer: React.FC<Props> = React.memo(({ size = 'lg' }) => {
  switch (size) {
    case 'lg':
      return <StyledSpacer size={48}></StyledSpacer>;
    case 'md':
      return <StyledSpacer size={32}></StyledSpacer>;
    case 'sm':
      return <StyledSpacer size={16}></StyledSpacer>;
    case 'xs':
      return <StyledSpacer size={8}></StyledSpacer>;
    default:
      return <StyledSpacer size={16}></StyledSpacer>;
  }
});

type StyledProps = {
  size: number;
};
export const StyledSpacer = styled.div<StyledProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;
