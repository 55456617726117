import React from 'react';
import { styled } from 'twin.macro';

export const FooterButton: React.FC = React.memo((props) => {
  return <StyledDiv>{props.children}</StyledDiv>;
});

const StyledDiv = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 8px;
  color: #212121;
  text-align: center;
  display: block;
  font-weight: 600;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;
