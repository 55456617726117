import { BigNumber as BN } from 'bignumber.js';
import { Token, TokenSym } from 'types';

export type ERC20Token = Token;

export const defaultView = (amount: BN, decimal = 18): string => {
  switch (true) {
    case decimal < 6:
      return amount.dp(2).toString(10);
    case decimal < 12:
      return amount.dp(3).toString(10);
    default:
      return amount.dp(4).toString(10);
  }
};

export const ERC20Map: Map<TokenSym, ERC20Token> = new Map([
  [
    'WETH',
    {
      name: 'Wrapped Ether',
      address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      symbol: 'WETH',
      decimals: 18,
      chainId: 1,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
    },
  ],
  [
    'WBTC',
    {
      name: 'Wrapped BTC',
      address: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
      symbol: 'WBTC',
      decimals: 8,
      chainId: 1,
      logoURI:
        'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png',
      view: (amount: BN): string => {
        return amount.dp(6).toString(10);
      },
    },
  ],
]);
