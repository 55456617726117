import React from 'react';
import { styled } from 'twin.macro';

export const SectionTitle: React.FC = React.memo(({ children }) => {
  return <StyledH2>{children}</StyledH2>;
});

const StyledH2 = styled.h2`
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 959px) {
    font-size: 18px;
    line-height: 20px;
  }

  #announcement & {
    font-weight: 12px;
    @media (max-width: 959px) {
      font-size: 18px;
      line-height: 20px;
    }
  }
`;
