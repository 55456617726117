import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from 'components/atoms/Box';
import { Center } from 'components/atoms/Center';
import { HeaderTitle, SectionTitle } from 'components/atoms/Heading';
import { Spacer } from 'components/atoms/Spacer';
import { TextRed } from 'components/atoms/TextRed';
import { Layout } from 'components/layouts';
import { WalletConnectButton } from 'components/wallet/ConnectButton';

export const IndexStop: React.FC = React.memo(() => {
  const history = useHistory();

  return (
    <Layout id="index">
      <Spacer size="md" />
      <HeaderTitle>
        FDAP
        <br />
        レンディングサービス
      </HeaderTitle>
      <Spacer size="md" />
      <Spacer size="lg" />
      <Box>
        <Spacer size="sm" />
        <Center>
          概要説明が入る
          <br />
          あいうえおかきくけこさしすせそたちつてとなにぬねのはひふへほまみむめもやいゆえよらりるれろわいうえをん
        </Center>
      </Box>
      <Spacer size="lg" />
      <Box>
        <SectionTitle>
          お申込み<TextRed>停止</TextRed>期間
        </SectionTitle>
        <Spacer size="sm" />
        <TextRed>2021/7/1～12/31</TextRed>
      </Box>
      <Spacer size="lg" />
      <WalletConnectButton
        onClick={() => {
          history.push('/lending/sorry');
        }}
      />
    </Layout>
  );
});
