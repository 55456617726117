import React from 'react';
import { styled } from 'twin.macro';

export const SectionList: React.FC = React.memo(({ children }) => {
  return <StyledUl>{children}</StyledUl>;
});

export const StyledUl = styled.ul`
  list-style-type: disc;
  padding-left: 24px;
`;
