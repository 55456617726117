import React from 'react';
import { Center } from 'components/atoms/Center';
import { HeaderTitle } from 'components/atoms/Heading';
import { Meter } from 'components/atoms/Meter';
import { Spacer } from 'components/atoms/Spacer';
import { Strong } from 'components/atoms/Strong';
import { TextRed } from 'components/atoms/TextRed';
import { Layout } from 'components/layouts';

export const Applying: React.FC = React.memo(() => {
  return (
    <Layout id="applying">
      <Spacer size="md" />
      <HeaderTitle>お申込み中</HeaderTitle>
      <Spacer size="md" />
      <Meter step="applying" />
      <Spacer size="sm" />
      <Center>
        <Strong>
          お申込みいただきありがとうございました。
          <br />
          現在処理中です。
          <br />
          お申込処理の完了後、自動的に画面が切り替わります。
          <Spacer size="sm" />
          <TextRed>※処理には30秒から数分程度かかる可能性がございます。</TextRed>
          <br />
          <Spacer size="sm" />
          <TextRed>
            ※GasPriceを変更してトランザクションを出し直された場合、画面上処理が完了しないことがございますが、
            MetaMask上にて処理が完了しておりましたら正常にお申込が完了しております。
            <br />
            マイページ画面をご確認ください。
          </TextRed>
        </Strong>
      </Center>
    </Layout>
  );
});
