import React from 'react';

type Props = {
  date: Date;
  monthOnly: boolean;
};

export const DateTimeLabel: React.FC<Props> = React.memo((props) => {
  const date = props.date;
  let label = '';
  if (props.monthOnly) {
    label =
      date.getFullYear().toString() + '/' + ('00' + (date.getMonth() + 1).toString()).slice(-2);
  } else {
    label =
      date.getFullYear().toString() +
      '/' +
      ('00' + (date.getMonth() + 1).toString()).slice(-2) +
      '/' +
      ('00' + date.getDate().toString()).slice(-2);
  }

  return <>{label}</>;
});
