import { useToggleButton } from '@react-aria/button';
import { useToggleState } from '@react-stately/toggle';
import React, { useRef } from 'react';
import { styled } from 'twin.macro';
import { AriaToggleButtonProps } from '@react-types/button'; //eslint-disable-line

type Props = AriaToggleButtonProps;

export const CheckboxButton: React.FC<Props> = React.memo((props) => {
  const ref = useRef(null);
  const state = useToggleState(props);
  const { buttonProps } = useToggleButton(props, state, ref);
  const { children } = props;

  return state.isSelected ? (
    <CheckedButton {...buttonProps}>{children}</CheckedButton>
  ) : (
    <StyledButton {...buttonProps}>{children}</StyledButton>
  );
});

const StyledButton = styled.button`
  //label
  cursor: pointer;cursorcursor
  font-size: 14px;font-size
  font-weight: 600;
  line-height: calc(48px - 2px);
  height: 48px;
  /* width: 300px; */
  width: 90%;
  border-radius: 0;
  border: 1px solid ${(props) => props.theme.color.darkBlue};
  background-color: ${(props) => props.theme.color.darkBlue};
  color: ${(props) => props.theme.color.white};
  display: inline-block;
  text-align: center;
  padding: 0;
  position: relative;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  z-index: 1;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;

  //max-width: 959px
  @media (max-width: 959px) {
    width: 100%;
    max-width: 300px;
  }

  &:hover {
    color: ${(props) => props.theme.color.darkBlue};
    border: 1px solid ${(props) => props.theme.color.darkBlue};
    background-color: ${(props) => props.theme.color.white};
    cursor: pointer;
  }

  &:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
    box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.darkBlue};
    border: 1px solid ${(props) => props.theme.color.darkBlue};
    outline: none;
  }

  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
    box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
    background-color: ${(props) => props.theme.color.white};
    color: ${(props) => props.theme.color.darkBlue};
    border: 1px solid ${(props) => props.theme.color.darkBlue};
    outline: none;
  }

  &:active::after {
    border-top: 2px solid ${(props) => props.theme.color.white};
    border-right: 2px solid ${(props) => props.theme.color.white};
  }

  &:focus::after {
    border-top: 2px solid ${(props) => props.theme.color.white};
    border-right: 2px solid ${(props) => props.theme.color.white};
  }
`;

const CheckedButton = styled(StyledButton)`
  border: 1px solid ${(props) => props.theme.color.grey[600]};
  background-color: ${(props) => props.theme.color.grey[600]};
  color: ${(props) => props.theme.color.grey[300]};
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
  cursor: inherit;
`;
