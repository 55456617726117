import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button'; //eslint-disable-line
import React, { useRef } from 'react';
import { styled } from 'twin.macro';

type Props = AriaButtonProps & {
  onClick?: () => void;
};

export const AgreementButton: React.FC<Props> = React.memo((props) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { children } = props;

  return (
    <StyledButton {...buttonProps} ref={ref} onClick={props.onClick}>
      {children}
    </StyledButton>
  );
});

const StyledButton = styled.button`
  font-size: 18px;
  font-weight: 600;
  line-height: calc(48px - 2px);
  height: 48px;
  width: 300px;
  border-radius: 20px;
  border: 1px solid #0069c0;
  background-color: #0069c0;
  color: #fff;
  display: inline-block;
  text-align: center;
  padding: 0;
  position: relative;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  transition: 0.2s linear;

  &:hover {
    color: #0069c0;
    border: 1px solid #0069c0;
    background-color: #fff;
    cursor: pointer;
  }

  &:active,
  &:focus {
    box-shadow: none;
    box-shadow: 1px 1px 2px #424242 inset;
    background-color: #fff;
    color: #0069c0;
    border: 1px solid #0069c0;
    outline: none;
  }

  &active::after,
  &:focus::after {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  &:disabled {
    background-color: #bdbdbd;
    color: #616161;
    border-color: #bdbdbd;
    box-shadow: none;
    opacity: 1;
    cursor: inherit;
  }

  @media (max-width: 959px) {
    width: 250px;
  }
`;
