import React from 'react';
import { Anchor } from 'components/atoms/Anchor';
import { Button } from 'components/atoms/Buttons';
import { Center } from 'components/atoms/Center';
import { HeaderTitle, SectionTitle } from 'components/atoms/Heading';
import { Spacer } from 'components/atoms/Spacer';
import { Strong } from 'components/atoms/Strong';
import { TextRed } from 'components/atoms/TextRed';
import { Layout } from 'components/layouts';

export const Announcement: React.FC = React.memo(() => {
  return (
    <Layout id="announcement">
      <Spacer size="md" />
      <HeaderTitle>
        次期ご利用開始時期は
        <br />
        下記になります。
      </HeaderTitle>
      <Spacer size="lg" />
      <Spacer size="md" />
      <SectionTitle>
        <TextRed>2022/1/1</TextRed>
      </SectionTitle>
      <Spacer size="lg" />
      <Center>
        <Strong>今後ともFDAPレンディングサービスをよろしくお願いいたします。</Strong>
      </Center>
      <Spacer size="lg" />
      <Anchor to="/lending/mypage">
        <Button>次へ</Button>
      </Anchor>
    </Layout>
  );
});
