import React from 'react';
import { Anchor } from 'components/atoms/Anchor';
import { Box } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { Center } from 'components/atoms/Center';
import { HeaderTitle } from 'components/atoms/Heading';
import { Spacer } from 'components/atoms/Spacer';
import { TextRed } from 'components/atoms/TextRed';
import { Layout } from 'components/layouts';

export const ContractError: React.FC = React.memo(() => {
  return (
    <Layout id="contract-error">
      <Spacer size="md" />
      <HeaderTitle>エラー</HeaderTitle>
      <Spacer size="lg" />
      <Box>
        <Center>
          <p>お申込内容が不適切であったためトランザクションが正常に処理されませんでした。</p>
          <p>お申込内容をご確認の上、再度お申込ください。</p>

          <Spacer size="sm" />
          <TextRed>
            ※GasPriceを変更してトランザクションを出し直された場合、画面上処理が完了しないことがございますが、
            MetaMask上にて処理が完了しておりましたら正常にお申込が完了しております。
            <br />
            マイページ画面をご確認ください。
          </TextRed>
        </Center>
      </Box>
      <Spacer size="lg" />
      <Anchor to="/lending/mypage">
        <Button>マイページへ戻る</Button>
      </Anchor>
    </Layout>
  );
});
