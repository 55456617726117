import { BigNumber as BN } from 'bignumber.js';
import { Contract } from 'web3-eth-contract';
import { MAX_UINT256, zeroAddress } from 'resource';
import { addEx, removeEx } from 'utils';

interface FsccInterface {
  address: string;
  contract: Contract;
  toBN: (amount: string, decimals?: BN) => Promise<BN>;
  fromBN: (amount: BN, decimals?: BN) => Promise<string>;
  balanceOf: (userAddress: string) => Promise<BN>;
  allowance: (owner: string, spender: string) => Promise<BN>;
  decimals: () => Promise<BN>;
  name: () => Promise<string>;
  symbol: () => Promise<string>;
  totalSupply: () => Promise<BN>;
  approve: (spender: string, amount: BN) => Promise<any>;
}

export default class Fscc implements FsccInterface {
  public address: string;
  public contract: Contract;
  private E8: number;
  private _decimals: BN | undefined;

  constructor(address: string, contract: Contract) {
    this.address = address;
    this.contract = contract;
    this.E8 = 8;
  }

  toBN = async (amount: string, decimals?: BN): Promise<BN> => {
    if (decimals) {
      return removeEx(amount, decimals.toNumber());
    }
    this._decimals = await this.decimals();

    return this.toBN(amount, this._decimals);
  };

  fromBN = async (amount: BN, decimals?: BN): Promise<string> => {
    if (decimals) {
      return addEx(amount, decimals.toNumber());
    }
    this._decimals = await this.decimals();

    return this.fromBN(amount, this._decimals);
  };

  balanceOf = async (userAddress: string) => {
    if (this.address === zeroAddress) return new BN(0);
    const balance = await this.contract?.methods.balanceOf(userAddress).call();

    return await this.toBN(balance, this._decimals);
  };

  allowance = async (owner: string, spender: string) => {
    if (this.address === zeroAddress) {
      return MAX_UINT256;
    }
    const allow = await this.contract?.methods.allowance(owner, spender).call();

    return await this.toBN(allow, this._decimals);
  };

  decimals = async () => {
    if (this._decimals) return this._decimals;

    return new BN(await this.contract?.methods.decimals().call());
  };

  name = async () => {
    if (this.address === zeroAddress) return 'ETH';

    return await this.contract?.methods.name().call();
  };

  symbol = async () => {
    if (this.address === zeroAddress) return 'ETH';

    return await this.contract?.methods.symbol().call();
  };

  totalSupply = async () => {
    const supply = await this.contract?.methods.totalSupply().call();

    return await this.toBN(supply, this._decimals);
  };

  approve = async (spender: string, amount: BN) => {
    const amountString = amount.isEqualTo(MAX_UINT256)
      ? '0x' + MAX_UINT256.toString(16)
      : await this.fromBN(amount);
    if (this.address == zeroAddress) throw Error('ETH do not have to approve');

    return this.contract?.methods.approve(spender, amountString);
  };
}
