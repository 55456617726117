import React from 'react';
import styled from 'styled-components';
import { Button } from 'components/atoms/Buttons';
import { Center } from 'components/atoms/Center';
import { FlexBox } from 'components/atoms/FlexBox';
import { SectionTitle } from 'components/atoms/Heading';
import { CloseIcon } from 'components/atoms/Icon';
import { Right } from 'components/atoms/Right';
import { Spacer } from 'components/atoms/Spacer';
import { TextRed } from 'components/atoms/TextRed';

type Props = {
  onClick?: () => void;
  onDismiss?: () => void;
};

export const CancelLendingCompleteModal: React.FC<Props> = React.memo(({ onDismiss }) => {
  return (
    <StyledBgDiv>
      <div
        onClick={() => {
          if (onDismiss) onDismiss();
        }}
      >
        <FlexBox>
          <Right>
            <CloseIcon />
          </Right>
        </FlexBox>
      </div>
      <SectionTitle>完了</SectionTitle>
      <Spacer size="sm" />
      <Center>自動更新停止のお申込をお受けしました。</Center>
      <Spacer size="sm" />
      <Center>
        <TextRed>
          貸借数及び貸借料は貸出状況画面より満期日をご確認の上、
          <StyledBr />
          満期後にお受取りください。
        </TextRed>
      </Center>
      <Spacer size="sm" />
      <Center>
        ※お申込期間中に貸出をキャンセルする場合は、
        <br />
        即時に貸借数が返却されます。
      </Center>
      <Spacer size="md" />
      <Button
        onClick={() => {
          if (onDismiss) {
            onDismiss();
            location.reload();
          }
        }}
      >
        完了
      </Button>
    </StyledBgDiv>
  );
});

const StyledBr = styled.br`
  display: block;

  @media (max-width: 959px) {
    display: none;
  }
`;

export const StyledBgDiv = styled.div`
  background-color: ${(props) => props.theme.color.grey[800]};
  padding: 16px;

  @media (max-width: 959px) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;
