import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button';//eslint-disable-line
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from 'twin.macro';
import { FlexBox } from '../FlexBox';
import { Right } from '../Right';
import { useFetch } from 'hooks/staking/useFetchStaking';

type Props = {
  onClick?: () => void;
};

export const Footer: React.FC<Props> = React.memo(({ onClick }) => {
  const fetch = useFetch();
  const history = useHistory();

  const accountInfo = fetch.accountInfo;
  // 解約済み情報が存在するか
  let isExistMaturityHistory = false;
  if (accountInfo?.nextAddedStakeAmountList) {
    const nextAddedStakeAmountList = accountInfo?.nextAddedStakeAmountList;
    nextAddedStakeAmountList.forEach((item) => {
      if (item.lt(0)) {
        isExistMaturityHistory = true;
      }
    });
  }

  let isWithdrawableStakingAmount = false;
  // ステーキング額が存在するか判定
  (accountInfo?.withdrawableStakingAmountList
    ? accountInfo?.withdrawableStakingAmountList
    : []
  ).map((v) => {
    if (v.toString() !== '0') {
      isWithdrawableStakingAmount = true;
    }
  });

  let contractDetailFlg = false;
  if (isExistMaturityHistory || isWithdrawableStakingAmount) {
    contractDetailFlg = true;
  }

  return (
    <StyledFooter>
      <StyledDiv>
        <StyledLendingButton>
          <a onClick={onClick}>
            <span>貸出</span>
          </a>
        </StyledLendingButton>
        <FlexBox>
          <ContractFooterButton
            isDisabled={!contractDetailFlg}
            onClick={() => {
              history.push('/lending/confirmation-contract-details');
            }}
          >
            貸出
            <br />
            状況
          </ContractFooterButton>

          <Right>
            <ContractFooterButton
              onClick={() => {
                history.push('/lending/mypage');
              }}
            >
              My
              <br />
              Page
            </ContractFooterButton>
          </Right>
        </FlexBox>
      </StyledDiv>
    </StyledFooter>
  );
});

type FooterProps = AriaButtonProps & {
  onClick?: () => void;
};

const ContractFooterButton: React.FC<FooterProps> = React.memo((props) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { children } = props;

  return (
    <StyledFooterDiv>
      <StyledFooterButton {...buttonProps} ref={ref} onClick={props.onClick}>
        {children}
      </StyledFooterButton>
    </StyledFooterDiv>
  );
});

const StyledFooter = styled.footer`
  width: 100%;
  padding: 16px;
  padding-left: 0px;
  background-color: ${(props) => props.theme.color.darkBlue};
  color: ${(props) => props.theme.color.white};
  position: absolute;
  bottom: 0;
  position: fixed;
  width: 100%;
  bottom: 0px;
`;

const StyledDiv = styled.div`
  position: relative;
  max-width: 496px;
  width: 100%;
  margin: 0 auto;
`;

const StyledLendingButton = styled.div`
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  -webkit-transform: translateY(-50%) translateX(-50%);
  margin: auto;
  top: -8px;
  z-index: 2;

  a {
    color: ${(props) => props.theme.color.white};
    text-decoration: none;
  }

  a :hover {
    cursor: pointer;
    color: ${(props) => props.theme.color.grey[400]};
  }

  span {
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    background-color: ${(props) => props.theme.color.darkBlue};
    display: block;
    border-radius: 50% 50% 0 0;
    padding: 20px;
  }

  @media (max-width: 959px) {
    span {
      font-size: 18px;
      line-height: 20px;
    }
  }
`;
const StyledFooterDiv = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 8px;
  color: #212121;
  text-align: center;
  display: block;
  font-weight: 600;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
`;

const StyledFooterButton = styled.button`
  height: 60px;
  width: 60px;
  background-color: ${(props) => props.theme.color.white};
  border-radius: 8px;
  color: #212121;
  text-align: center;
  display: block;
  font-weight: 600;
  line-height: 1.2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
