import BigNumber, { BigNumber as BN } from 'bignumber.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from 'twin.macro';
import { Box, BoxBg } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { AgreementButton } from 'components/atoms/Buttons/AgreementButton';
import { Center } from 'components/atoms/Center';
import { HeaderTitle, SectionTitle } from 'components/atoms/Heading';
import { Meter } from 'components/atoms/Meter';
import { Spacer } from 'components/atoms/Spacer';
import { TextRed } from 'components/atoms/TextRed';
import { ContractStartDateTimeLabel } from 'components/datetime/ContractStartDateTimeLabel';
import { Layout } from 'components/layouts';
import { setLoading } from 'features/handover';
import { RootState } from 'features/reducer';
import { useFscc } from 'hooks/fscc/useFscc';
import { useTxFscc } from 'hooks/fscc/useTxFscc';
import { useStaking } from 'hooks/staking/useStaking';

export const ApplicationConfirmation: React.FC = React.memo(() => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);
  const amount = useSelector((state: RootState) => state.handover.amount);
  const expectedReward = useSelector((state: RootState) => state.handover.expectedReward);
  const sendAmount: BN = new BigNumber(amount);
  const txFscc = useTxFscc();
  const fscc = useFscc();
  const staking = useStaking();
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (amount == 0) {
      history.replace('/lending/form');
    }
  }, [amount, history]);

  useEffect(() => {
    if (fscc.allowance.gte(new BN(amount))) {
      if (!initial) {
        dispatch(setLoading(false));
        setConfirmed(true);
      }
    }
    setInitial(false);

    return () => {
      setInitial(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fscc.allowance, amount]);

  return (
    <>
      <Layout id="application-confirmation">
        <Spacer size="md" />
        <HeaderTitle>確認</HeaderTitle>
        <Spacer size="md" />
        <Meter step="applying" />
        <Spacer size="sm" />
        <SectionTitle>お申込み内容</SectionTitle>
        <StyledDiv>こちらの内容で申込みますか？</StyledDiv>

        <Spacer size="lg" />

        <Box>
          <SectionTitle>通貨</SectionTitle>
          <StyledDiv>FISCOコイン</StyledDiv>

          <Spacer size="md" />

          <SectionTitle>貸出期間</SectionTitle>
          <StyledDiv>
            <Center>168日間コース</Center>
          </StyledDiv>

          <Spacer size="md" />

          <SectionTitle>貸借数</SectionTitle>
          <StyledDiv>{amount} 枚</StyledDiv>

          <Spacer size="md" />

          <SectionTitle>貸借料(予定)</SectionTitle>
          <StyledDiv>{expectedReward.toString()} 枚</StyledDiv>
          <Spacer size="md" />

          <SectionTitle>ご利用開始</SectionTitle>
          <ContractStartDateTimeLabel isTop={false} />

          <Spacer size="lg" />
          <SectionTitle>
            <TextRed>
              ご注意ください。
              <br />
              貸出期間中に貸出を
              <br />
              中止することはできません。
            </TextRed>
          </SectionTitle>

          <Spacer size="lg" />

          <BoxBg>
            <ConfirmationAgree>
              FISCOコインを
              <br />
              レンディングアプリケーションに
              <br />
              送ることに同意する
              <br />
              <br />
              {!confirmed && fscc.allowance.gte(sendAmount) ? (
                <TextRed>※既に送金することに同意されているため本手順をスキップしました。</TextRed>
              ) : (
                <TextRed>※処理には30秒から数分程度かかる可能性がございます。</TextRed>
              )}
            </ConfirmationAgree>
            <Spacer size="md" />
            <AgreementButton
              isDisabled={confirmed || fscc.allowance.gte(sendAmount)}
              onClick={() => {
                if (sendAmount.eq(0)) {
                  history.push('/lending/form');
                } else {
                  txFscc
                    .approve(sendAmount)
                    .then(() => {
                      setConfirmed(true);
                    })
                    .finally(() => {
                      dispatch(setLoading(false));
                    });
                  dispatch(setLoading(true));
                }
              }}
            >
              同意する
            </AgreementButton>
          </BoxBg>
        </Box>

        <Spacer size="lg" />
        <Button
          isDisabled={!(confirmed || fscc.allowance.gte(sendAmount))}
          onClick={() => {
            history.push('/lending/applying');
            staking
              .stake(sendAmount)
              .then(() => history.push('/lending/applied'))
              .catch(() => history.push('/lending/contract-error'));
          }}
        >
          申し込む
        </Button>
      </Layout>
    </>
  );
});

const StyledDiv = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
`;

const ConfirmationAgree = styled.div`
  text-align: center;
  font-size: 22px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  font-weight: 400;

  @media (max-width: 959px) {
    font-size: 18px;
    line-height: 20px;
  }
`;
