import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../FlexBox';

type Props = {
  value: any;
  unit?: string;
  position?: 'center' | 'right';
};

export const SimpleValue: React.FC<Props> = React.memo((props) => {
  return (
    <StyledFlexBox position={props.position || 'right'}>
      <StyledValue>{props.value}</StyledValue>
      {props.unit ? <StyledUnit>{props.unit}</StyledUnit> : <></>}
    </StyledFlexBox>
  );
});

const StyledValue = styled.span`
  margin-top: 8px;
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
`;

const StyledUnit = styled.span`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
`;

const StyledFlexBox = styled(FlexBox)<{
  position?: 'center' | 'right';
}>`
  ${({ position }) => (position === 'right' ? 'margin-left: auto' : '')};
  ${({ position }) => (position === 'center' ? 'margin: 0 auto;' : '')}
`;
