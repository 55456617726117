import React from 'react';
import { styled } from 'twin.macro';
import { StyledBgDiv } from './BoxBg';

export const BoxHeader: React.FC = React.memo((props) => {
  const { children } = props;

  return <StyledDiv>{children}</StyledDiv>;
});

const StyledDiv = styled(StyledBgDiv)`
  //area-border
  border: 1px solid ${(props) => props.theme.color.grey[700]};
  padding: 16px;

  //item-header
  border-bottom: none;
`;
