import { RadioAriaProps, useRadio } from '@react-aria/radio';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { RadioGroupState, useRadioGroupState } from '@react-stately/radio';
import { RadioGroupProps } from '@react-types/radio'; // eslint-disable-line
import React, { Children, createContext, useContext, useRef } from 'react';
import { styled } from 'twin.macro';

type Props = RadioGroupProps;

const RadioContext = createContext<RadioGroupState | null>(null);

export const RadioGroup: React.FC<Props> = React.memo((props) => {
  const state = useRadioGroupState(props);
  const n = Children.count(props.children);

  return (
    <StyledUl n={n}>
      <RadioContext.Provider value={state}>{props.children}</RadioContext.Provider>
    </StyledUl>
  );
});

export const Radio: React.FC<
  RadioAriaProps & {
    checked?: boolean;
  }
> = React.memo((props) => {
  const state = useContext(RadioContext);
  const ref = useRef(null);
  const { inputProps } = useRadio(props, state as RadioGroupState, ref);
  const checked = props.checked || inputProps.checked;

  return (
    <StyledLabel checked={checked}>
      <VisuallyHidden>
        <StyledInput {...inputProps} ref={ref} />
      </VisuallyHidden>
      {props.children}
    </StyledLabel>
  );
});

const StyledUl = styled.ul<{
  n: number;
}>`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin: 20px auto;
  text-align: center;
  width: 100%;

  label {
    font-size: 12px;
    list-style: none;
    width: ${(props) => 100 / props.n}%;
  }
`;

const StyledInput = styled.input`
  display: none;

  :checked & :after {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: 4px;
    width: 18px;
    height: 18px;
    background: ${(props) => props.theme.color.darkBlue};
    border-radius: 50%;
  }
`;

type LabelProps = {
  checked?: boolean;
};

const StyledLabel = styled.label<LabelProps>`
  margin-right: 0;
  text-align: left;
  padding-left: 32px;
  position: relative;
  margin-right: 20px;
  line-height: 16px;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    left: 0;
    width: 24px;
    height: 24px;
    border: 1px solid ${(props) => props.theme.color.grey[600]};
    border-radius: 50%;
  }

  & span {
    font-size: 30px;
    font-weight: 400;
  }

  @media (max-width: 959px) {
    & span {
      font-size: 30px;
    }
  }

  :after {
    content: '';
    display: ${(props) => (props.checked ? 'block' : 'none')};
    position: absolute;
    top: -2px;
    left: 4px;
    width: 18px;
    height: 18px;
    background: ${(props) => props.theme.color.darkBlue};
    border-radius: 50%;
  }
`;
