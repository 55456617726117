import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { settingSlice } from 'features/setting';

export const SettingProvider: React.FC = React.memo(
  ({ children }): JSX.Element => {
    const dispatch = useDispatch();
    const { init } = settingSlice.actions;

    const dispatchSetting = useCallback(() => {
      dispatch(init());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
      dispatchSetting();
    }, [dispatchSetting]);

    return <>{children}</>;
  },
);
