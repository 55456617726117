import { useSelector } from 'react-redux';
import { useTxStaking } from './useTxStaking';
import { RootState } from 'features/reducer';
import {
  apySelector,
  cycleSelector,
  receivableRewardSelector,
  withdrawableStakingAmountSelector,
} from 'selector/staking';

export const useStaking = () => {
  const receivableReward = useSelector((state: RootState) => receivableRewardSelector(state));
  const withdrawableStakingAmount = useSelector((state: RootState) =>
    withdrawableStakingAmountSelector(state),
  );
  const cycle = useSelector((state: RootState) => cycleSelector(state));
  const apy = useSelector((state: RootState) => apySelector(state));
  const { stake, withdraw, receiveReward } = useTxStaking();

  return {
    receivableReward,
    withdrawableStakingAmount,
    cycle,
    apy,
    stake,
    withdraw,
    receiveReward,
  };
};
