import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber as BN } from 'bignumber.js';
import { ContractAddr } from 'types';

export type StakingConfig = {
  startTimestamp: Date;
  termInterval: Date;
};

export const defaultStakingConfig = (): StakingConfig => {
  return {
    startTimestamp: new Date(),
    termInterval: new Date(),
  };
};

export type StakingTokenInfo = {
  latestTerm: number;
  totalRemainingRewards: BN;
  currentTermReward: BN;
  nextTermRewards: BN;
  currentStaking: BN;
  nextTermStaking: BN;
};

export const defaultStakingTokenInfo = (): StakingTokenInfo => {
  return {
    latestTerm: 0,
    totalRemainingRewards: new BN(0),
    currentTermReward: new BN(0),
    nextTermRewards: new BN(0),
    currentStaking: new BN(0),
    nextTermStaking: new BN(0),
  };
};

export type StakingAccountInfo = {
  userTerm: number;
  stakeAmount: BN;
  nextAddedStakeAmount: BN;
  currentTermUserReward: BN;
  nextTermUserReward: BN;
  withdrawableStakingAmount: BN;
};

export type StakingAccountState = {
  receivableReward: BN;
} & StakingAccountInfo;

export const defaultStakingAccountInfo = (): StakingAccountState => {
  return {
    userTerm: 0,
    stakeAmount: new BN(0),
    nextAddedStakeAmount: new BN(0),
    currentTermUserReward: new BN(0),
    nextTermUserReward: new BN(0),
    withdrawableStakingAmount: new BN(0),
    receivableReward: new BN(0),
  };
};

type AccountInfo = Record<ContractAddr, StakingAccountState>;
type TokenInfo = Record<ContractAddr, StakingTokenInfo>;
type Configs = Record<ContractAddr, StakingConfig>;

type StakingSlice = {
  accountInfo: AccountInfo;
  tokenInfo: TokenInfo;
  configs: Configs;
};

const initialState: StakingSlice = {
  accountInfo: {},
  tokenInfo: {},
  configs: {},
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    updateAccount: (
      state,
      action: PayloadAction<{
        contract: ContractAddr;
        accountInfo: StakingAccountState;
      }>,
    ) => {
      const added: AccountInfo = {};
      added[action.payload.contract] = action.payload.accountInfo;

      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          ...added,
        },
      };
    },
    updateTokenInfo: (
      state,
      action: PayloadAction<{
        contract: ContractAddr;
        tokenInfo: StakingTokenInfo;
      }>,
    ) => {
      const added: TokenInfo = {};
      added[action.payload.contract] = action.payload.tokenInfo;

      return {
        ...state,
        tokenInfo: {
          ...state.tokenInfo,
          ...added,
        },
      };
    },
    updateConfig: (
      state,
      action: PayloadAction<{
        contract: ContractAddr;
        configs: StakingConfig;
      }>,
    ) => {
      const added: Configs = {};
      added[action.payload.contract] = action.payload.configs;

      return {
        ...state,
        configs: {
          ...state.configs,
          ...added,
        },
      };
    },
    reset: () => initialState,
  },
});
