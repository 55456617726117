import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useWallet } from 'use-wallet';
import { AbiItem } from 'web3-utils';
import { sdkSlice } from 'features/sdk';
import Fisco from 'fisco/fisco';
import { zeroAddress } from 'resource';
import StakingAggregatorABI from 'resource/abi/StakingAggregator.json';
import FsccABI from 'resource/abi/erc20.json';
import { chainID, contractAddresses, WEB3_PROVIDER_URL } from 'resource/constant';

const FiscoSdkProvider: React.FC = ({ children }) => {
  const {
    account,
    ethereum,
    status,
  }: { account: string | null; ethereum: any; status: string } = useWallet();
  const walletAddress = account || zeroAddress;
  const { initialize } = sdkSlice.actions;
  const dispatch = useDispatch();

  const dispatchFiscoSdk = useCallback(() => {
    if (status === 'connected') {
      const networkId = chainID;
      const sdk = new Fisco(
        ethereum,
        networkId,
        walletAddress,
        {
          // fiscoCoin
          abi: FsccABI as AbiItem[],
          address: contractAddresses.fiscoCoin[networkId],
        },
        {
          // stakingAggregator
          abi: StakingAggregatorABI as AbiItem[],
          address: contractAddresses.stakingAggregator[networkId],
        },
      );
      dispatch(initialize(sdk));
    } else {
      if (location.pathname === '/lending' || location.pathname === '/lending/') {
        const networkId = chainID;
        const sdk = new Fisco(
          WEB3_PROVIDER_URL[networkId],
          networkId,
          walletAddress,
          {
            // fiscoCoin
            abi: FsccABI as AbiItem[],
            address: contractAddresses.fiscoCoin[networkId],
          },
          {
            // stakingAggregator
            abi: StakingAggregatorABI as AbiItem[],
            address: contractAddresses.stakingAggregator[networkId],
          },
        );
        dispatch(initialize(sdk));
      }
    }
  }, [dispatch, status, walletAddress]); // eslint-disable-line

  useEffect(() => {
    dispatchFiscoSdk();
  }, [dispatchFiscoSdk]);

  return <>{children}</>;
};

export default FiscoSdkProvider;
