import { BigNumber as BN } from 'bignumber.js';
import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { useWallet } from 'use-wallet';
import { RootState } from 'features/reducer';
import Fisco from 'fisco/fisco';
import { defaultRefreshInterval, zeroAddress } from 'resource';

export const useFscc = () => {
  const fisco = useSelector((state: RootState) => state.sdk.fisco);
  const account = useWallet().account || zeroAddress;

  const balanceFetcher = (_: string, fisco: Fisco | null, a: string) => fisco?.fscc.balanceOf(a);
  const { data: balance } = useSWR(['/fscc/balance', fisco, account], balanceFetcher, {
    refreshInterval: defaultRefreshInterval,
  });

  const allowanceFetcher = (_: string, fisco: Fisco | null, a: string) =>
    fisco?.fscc.allowance(a, fisco.stakingAggregator.address);
  const { data: allowance } = useSWR(['/fscc/allowance', fisco, account], allowanceFetcher, {
    refreshInterval: defaultRefreshInterval,
  });

  return {
    balance: balance || new BN(0),
    allowance: allowance || new BN(0),
  };
};
