import axios from 'axios';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFetch } from 'hooks/staking/useFetchStaking';
import { useStaking } from 'hooks/staking/useStaking';
import { createNextStartTimestamp } from 'utils/date';

type Props = {
  isTop: boolean;
};

export const ContractStartDateTimeLabel: React.FC<Props> = React.memo((props) => {
  const staking = useStaking();
  const fetch = useFetch();
  const cycle = staking.cycle;
  const startTimestampList = fetch.config?.startTimestamp ? fetch.config?.startTimestamp : [];
  const [datetime, setDatetime] = useState<string>('');
  useEffect(() => {
    let isMounted = true;
    const fetchDateTime = async () => {
      const result = await axios.head('https://fiscocoin.com/lending');
      // const result = await axios.head('https://test-lending.f5.si/lending');
      // const result = await axios.head('http://localhost:3000/lending');
      if (isMounted) {
        setDatetime(result.headers.date);
      }
    };
    fetchDateTime();

    return () => {
      isMounted = false;
    };
  }, []);

  let displayDateTime = '';

  if (startTimestampList.length > 0 && cycle > 0) {
    const nextStartDate = createNextStartTimestamp(
      cycle,
      startTimestampList[0],
      new Date(datetime),
    );

    if (nextStartDate) {
      displayDateTime =
        nextStartDate.getFullYear().toString() +
        '/' +
        ('00' + (nextStartDate.getMonth() + 1).toString()).slice(-2) +
        '/' +
        ('00' + nextStartDate.getDate().toString()).slice(-2);
    }
  }

  return displayDateTime != '' ? (
    props.isTop ? (
      <>{displayDateTime} 9:00</>
    ) : (
      <StyledDiv>{displayDateTime}</StyledDiv>
    )
  ) : (
    <></>
  );
});

const StyledDiv = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
`;
