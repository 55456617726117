import { black, backdrop, red, white, lightBlue, darkBlue, grey, sectionBG } from './colors';

const theme = {
  buttonSize: {
    lg: 72,
    md: 48,
    sm: 36,
  },
  borderRadius: 12,
  fontWeight: {
    light: 200,
    regular: 400,
    bold: 600,
  },
  breakpoints: {
    mobile: 400,
  },
  color: {
    black,
    red,
    lightBlue,
    darkBlue,
    backdrop,
    grey,
    white,
    sectionBG,
  },
  spacing: {
    0: 0,
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
    8: 72,
    9: 96,
  },
  zIndex: {
    modal: 100,
  },
};

export default theme;
