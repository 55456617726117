import React from 'react';
import { styled } from 'twin.macro';
import { BoxBg } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { Center } from 'components/atoms/Center';
import { FlexBox } from 'components/atoms/FlexBox';
import { SectionTitle } from 'components/atoms/Heading';
import { CloseIcon } from 'components/atoms/Icon';
import { Right } from 'components/atoms/Right';
import { Spacer } from 'components/atoms/Spacer';

type Props = {
  onDismiss?: () => void;
};

export const CourseDetailModal: React.FC<Props> = React.memo(({ onDismiss }) => {
  return (
    <StyledDiv>
      <BoxBg>
        <div
          onClick={() => {
            if (onDismiss) onDismiss();
          }}
        >
          <FlexBox>
            <Right>
              <CloseIcon />
            </Right>
          </FlexBox>
        </div>
        <Spacer size="sm" />
        <SectionTitle>コース詳細</SectionTitle>
        <Spacer size="sm" />
        <Center>
          <StyledSpan>24</StyledSpan>週間<StyledSpan>168</StyledSpan>日間コース
        </Center>
        <Spacer size="sm" />
        ※お申込みいただいた貸し出し期間途中でご出金されますと貸出料金は支払われなくなります。予めご了承ください。
        <Spacer size="lg" />
        <Button
          onClick={() => {
            if (onDismiss) onDismiss();
          }}
        >
          戻る
        </Button>
      </BoxBg>
    </StyledDiv>
  );
});

const StyledDiv = styled.div`
  width: 400px;
  font-size: 14px;
`;

const StyledSpan = styled.span`
  font-size: 30px;
  font-weight: 400;
`;
