import { BigNumber as BN } from 'bignumber.js';
import { createSelector } from 'reselect';
import { RootState } from 'features/reducer';
import { daysPerYear } from 'resource';

export const stakingSelector = (state: RootState) => state.staking;

const _receivableRewardSelector = () => {
  const selector = createSelector(stakingSelector, (staking) =>
    Object.values(staking.accountInfo).reduce(
      (totalReceivableReward, accountInfo) =>
        accountInfo.currentTermUserReward.plus(totalReceivableReward),
      new BN(0),
    ),
  );

  return selector;
};

export const receivableRewardSelector = (state: RootState) => {
  const selector = _receivableRewardSelector();

  return selector(state);
};

const _withdrawableStakingAmountSelector = () => {
  const selector = createSelector(stakingSelector, (staking) =>
    Object.values(staking.accountInfo).reduce(
      (totalwithdrawableStakingAmount, accountInfo) =>
        accountInfo.withdrawableStakingAmount.plus(totalwithdrawableStakingAmount),
      new BN(0),
    ),
  );

  return selector;
};

export const withdrawableStakingAmountSelector = (state: RootState) => {
  const selector = _withdrawableStakingAmountSelector();

  return selector(state);
};

const _cycleSelector = () => {
  const selector = createSelector(stakingSelector, (staking) => {
    const configList = Object.values(staking.configs);
    if (configList.length) {
      return configList[0].termInterval.getTime();
    } else {
      return 0;
    }
  });

  return selector;
};

export const cycleSelector = (state: RootState) => {
  const selector = _cycleSelector();

  return selector(state);
};

const _apySelector = () => {
  const selector = createSelector(stakingSelector, (staking) => {
    const configState = Object.entries(staking.configs).sort((_a, _b) => {
      const a =
        _a[1].termInterval.getTime() +
        ((new Date().getTime() - _a[1].startTimestamp.getTime()) % _a[1].termInterval.getTime());

      const b =
        _b[1].termInterval.getTime() +
        ((new Date().getTime() - _b[1].startTimestamp.getTime()) % _b[1].termInterval.getTime());

      return a - b;
    });

    if (!configState.length) {
      return new BN(0);
    }
    const stakeTargetAddress = configState[5][0];

    const tokenInfoState = Object.entries(staking.tokenInfo).find(
      ([contractAddress]) => contractAddress === stakeTargetAddress,
    );

    if (tokenInfoState) {
      const [, tokenInfo] = tokenInfoState;
      const { nextTermRewards, nextTermStaking } = tokenInfo;
      if (nextTermRewards.isZero()) return new BN(0);
      if (nextTermStaking.isZero()) return new BN(0);

      return nextTermRewards
        .dividedBy(nextTermStaking)
        .times(daysPerYear)
        .times(100)
        .dividedBy(168);
    } else {
      return new BN(0);
    }
  });

  return selector;
};

export const apySelector = (state: RootState) => {
  const selector = _apySelector();

  return selector(state);
};
