import React from 'react';
import { styled } from 'twin.macro';

export const TextIndentOne: React.FC = React.memo(({ children }) => {
  return <StyledP>{children}</StyledP>;
});

const StyledP = styled.p`
  padding-left: 1em;
  text-indent: -1em;
`;
