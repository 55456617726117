import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BigNumber as BN } from 'bignumber.js';

export type FSCCState = {
  balance: BN;
  allowanceForStaking: BN;
  isLoading: boolean;
};
const initialState: FSCCState = {
  balance: new BN(0),
  allowanceForStaking: new BN(0),
  isLoading: false,
};

export const fsccSlice = createSlice({
  name: 'fscc',
  initialState,
  reducers: {
    update: (
      state: any,
      action: PayloadAction<{
        balance: BN;
        allowanceForStaking: BN;
      }>,
    ) => {
      return {
        balance: action.payload.balance,
        allowanceForStaking: action.payload.allowanceForStaking,
        isLoading: false,
      };
    },
    load: (state) => {
      return { ...state, isLoading: true };
    },
    reset: () => initialState,
  },
});
