import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useWallet } from 'use-wallet';

type Props = {
  onClick: () => void;
};

export const HeaderConnectButton: React.FC<Props> = React.memo(({ onClick }) => {
  const connect = useWallet().connect as (chain: string) => Promise<void>;
  const status = useWallet().status;
  const reset = useWallet().reset;
  const [clicked, setClicked] = useState<boolean>(false);
  const history = useHistory();

  // console.log('ethereum', ethereum, window.ethereum);

  const handleClick = async () => {
    try {
      if (!window.ethereum) {
        reset();
        history.push('/lending/no-environment');
      }
      if (status !== 'connected') {
        await connect('injected');
      }
      setClicked(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (clicked && status === 'connected') onClick();
  }, [clicked, status]); // eslint-disable-line

  return <StyledButton onClick={handleClick}>スタート</StyledButton>;
});

const StyledButton = styled.button`
  font-size: 13px;
  line-height: calc(48px - 2px);
  font-weight: 700;
  width: 100px;
  border-radius: 20px;
  background-color: #f5f7fa;
  text-align: center;
  display: block;
  overflow: hidden;
  z-index: 1;
  transition: 0.2s linear;
  margin-right: 8px;
  border: 1px solid #0069c0;

  &:hover {
    color: #0069c0;
    border: 1px solid #0069c0;
    background-color: #fff;
    cursor: pointer;
  }
`;
