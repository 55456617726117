import React, { useState } from 'react';
import { Radio, RadioGroup } from 'components/atoms/RadioGroup';
import { Spacer } from 'components/atoms/Spacer';
import { FormAmountText } from 'components/atoms/Text';
import { useFscc } from 'hooks';

type Props = {
  isError?: boolean;
};

export const RateRadio: React.FC<Props> = React.memo((props) => {
  const { balance } = useFscc();
  const [amount, setAmount] = useState<string>('0');
  const calcAmount = (value: string) => {
    setAmount(balance.times(Number(value)).toString());
  };

  return (
    <>
      <FormAmountText val={amount} isError={props.isError} />
      <Spacer size="xs" />
      <RadioGroup label="per" onChange={calcAmount}>
        <Radio value="0.25">25%</Radio>
        <Radio value="0.50">50%</Radio>
        <Radio value="0.75">75%</Radio>
        <Radio value="1.00">100%</Radio>
      </RadioGroup>
    </>
  );
});
