import { BigNumber as BN } from 'bignumber.js';
export const toDateString = (date: Date): string => {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const dateStr = `${y}/${m}/${d}`;

  return dateStr;
};

export const toDate = (value: string | undefined): Date => {
  return new Date(new BN(value || '0').toNumber() * 1000);
};

export const createNextStartTimestamp = (cycle: number, startTimestamp: Date, now: Date): Date => {
  // 現在サイクル
  const currentCycle = Math.floor((now.getTime() - startTimestamp.getTime()) / cycle);
  // term1開始からの経過ミリ秒
  const currentTimeFromTerm1 = (now.getTime() - startTimestamp.getTime()) % cycle;
  // term1開始から何term目の申込いるか
  const currentTerm = Math.floor(currentTimeFromTerm1 / (cycle / 6));

  // 開始日時 + サイクルミリ秒数 * 現在サイクル数 + (現term + 1) * 1termミリ秒数
  return new Date(
    startTimestamp.getTime() + cycle * currentCycle + ((currentTerm + 1) * cycle) / 6,
  );
};

export const createNextTermMaturityDate = (
  cycle: number,
  startTimestamp: Date,
  now: Date,
): Date => {
  // 次回開始時期の1サイクル後
  return new Date(createNextStartTimestamp(cycle, startTimestamp, now).getTime() + cycle);
};

export const getCurrentTerm = (cycle: number, startTimestamp: Date): number => {
  const now = new Date();
  const currentTimeFromTerm1 = (now.getTime() - startTimestamp.getTime()) % cycle;

  return Math.floor(currentTimeFromTerm1 / (cycle / 6)) + 1 == 6
    ? 0
    : Math.floor(currentTimeFromTerm1 / (cycle / 6)) + 1;
};

export const getMaturityDate = (
  cycle: number,
  startTimestamp: Date,
  index: number,
  now: Date,
): Date => {
  const currentCycle = Math.floor((now.getTime() - startTimestamp.getTime()) / cycle);
  const currentTimeFromTerm1 = (now.getTime() - startTimestamp.getTime()) % cycle;
  const currentTerm = Math.floor(currentTimeFromTerm1 / (cycle / 6)) + 1;

  if (currentTerm == 6 && index == 0) {
    return new Date(
      startTimestamp.getTime() + cycle * currentCycle + (cycle / 6) * index + cycle * 2,
    );
  } else if (currentTerm >= index) {
    // 現在term > ステーキング先indexの場合、次のサイクルのtermが満期になる
    return new Date(startTimestamp.getTime() + cycle * currentCycle + (cycle / 6) * index + cycle);
  } else {
    // ステーキング先index > 現在termの場合、今のサイクルのtermが満期になる
    return new Date(startTimestamp.getTime() + cycle * currentCycle + (cycle / 6) * index);
  }
};

export const getMaturityDateForCancellation = (
  cycle: number,
  startTimestamp: Date,
  index: number,
  now: Date,
): Date => {
  const currentCycle = Math.floor((now.getTime() - startTimestamp.getTime()) / cycle);
  const currentTimeFromTerm1 = (now.getTime() - startTimestamp.getTime()) % cycle;
  const currentTerm = Math.floor(currentTimeFromTerm1 / (cycle / 6)) + 1;

  if (currentTerm == 6 && index == 0) {
    return new Date(startTimestamp.getTime() + cycle * currentCycle + (cycle / 6) * index + cycle);
  } else if (currentTerm >= index) {
    return new Date(startTimestamp.getTime() + cycle * currentCycle + (cycle / 6) * index);
  } else {
    return new Date(startTimestamp.getTime() + cycle * currentCycle + (cycle / 6) * index - cycle);
  }
};
