import React from 'react';
import styled from 'styled-components';

export const LPHeaderConnectButton: React.FC = React.memo(() => {
  return <StyledButton disabled={true}>スタート</StyledButton>;
});

const StyledButton = styled.button`
  font-size: 13px;
  line-height: calc(48px - 2px);
  font-weight: 700;
  width: 100px;
  border-radius: 20px;
  background-color: #f5f7fa;
  text-align: center;
  display: block;
  overflow: hidden;
  z-index: 1;
  transition: 0.2s linear;
  margin-right: 8px;
  border: 1px solid #0069c0;

  &:hover {
    color: #0069c0;
    border: 1px solid #0069c0;
    background-color: #fff;
    cursor: pointer;
  }
`;
