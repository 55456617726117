import React from 'react';
import { Spacer } from 'components/atoms/Spacer';
import { Layout } from 'components/layouts';

export const Sorry: React.FC = React.memo(() => {
  return (
    <Layout id="sorry">
      <Spacer size="lg" />
      <p>
        申し訳ございません
        <br />
        現在、サービスの申込み期間外となり、
        <br />
        新規のお申込みは受け付けておりません
      </p>
      <Spacer size="sm" />
      <p>
        次回のお申込み期間は
        <br />
        2022/1/1～6/30
        <br />
        になります
      </p>
      <Spacer size="sm" />
      <p>
        今後ともFDAPレンディングサービスを
        <br />
        よろしくお願いいたします
      </p>
    </Layout>
  );
});
