import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { stakingSlice } from '../../features/staking';
import { useFetch } from './useFetchStaking';
import { RootState } from 'features/reducer';

export const useDispatchStaking = () => {
  const dispatch = useDispatch();
  const { tokenInfo, accountInfo, config, receivableReward, contracts } = useFetch();
  const { fisco } = useSelector((state: RootState) => state.sdk);
  const { updateAccount, updateTokenInfo, updateConfig } = stakingSlice.actions;

  const updateStakingState = () => {
    try {
      // if (!fisco) throw new Error('undefined sdk');
      if (!(tokenInfo && accountInfo && config && receivableReward && contracts)) return;

      contracts.map((contactAddress: string, index: number) => {
        dispatch(
          updateTokenInfo({
            contract: contactAddress,
            tokenInfo: {
              latestTerm: tokenInfo.latestTermList[index],
              totalRemainingRewards: tokenInfo.totalRemainingRewardsList[index],
              currentTermReward: tokenInfo.currentTermRewardsList[index],
              nextTermRewards: tokenInfo.nextTermRewardsList[index],
              currentStaking: tokenInfo.currentStakingList[index],
              nextTermStaking: tokenInfo.nextTermStakingList[index],
            },
          }),
        );
        dispatch(
          updateAccount({
            contract: contactAddress,
            accountInfo: {
              userTerm: accountInfo.userTermList[index],
              stakeAmount: accountInfo.stakeAmountList[index],
              nextAddedStakeAmount: accountInfo.nextAddedStakeAmountList[index],
              currentTermUserReward: accountInfo.currentTermUserRewardsList[index],
              nextTermUserReward: accountInfo.nextTermUserRewardsList[index],
              withdrawableStakingAmount: accountInfo.withdrawableStakingAmountList[index],
              receivableReward: receivableReward,
            },
          }),
        );
        dispatch(
          updateConfig({
            contract: contactAddress,
            configs: {
              startTimestamp: config.startTimestamp[index],
              termInterval: config.termInterval,
            },
          }),
        );
      });
    } catch (error) {
      //TODO: Error handling and update to UI
      console.error(error);
    }
  };

  const memorizedUpdateStakingState = useCallback(() => {
    updateStakingState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fisco, tokenInfo, accountInfo, config, receivableReward, contracts]);

  return { memorizedUpdateStakingState };
};
