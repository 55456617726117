import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import Fisco from 'fisco/fisco';
import { chainID } from 'resource/constant';

declare global {
  interface Window {
    ethereum: any;
    web3: any;
  }
}

export type SDK = {
  fisco: Fisco | null;
  chainId: number;
};

const initialState: SDK = {
  fisco: null,
  chainId: chainID,
};

const initialize: CaseReducer<SDK, PayloadAction<Fisco>> = (state, action) => {
  return {
    ...state,
    fisco: action.payload,
  } as SDK;
};

const setChainId: CaseReducer<SDK, PayloadAction<number>> = (state, action) => {
  return {
    ...state,
    chainId: action.payload,
  } as SDK;
};
export const sdkSlice = createSlice({
  name: 'sdk',
  initialState,
  reducers: {
    initialize,
    setChainId,
  },
});
