import React from 'react';

export type Props = {
  id:
    | 'announcement'
    | 'application-confirmation'
    | 'applied'
    | 'applying'
    | 'contract-details'
    | 'application'
    | 'index'
    | 'introduction'
    | 'mypage'
    | 'sorry'
    | 'terms-of-service'
    | 'contract-error'
    | 'not-found'
    | 'landing-page';
};

export const IdWrap: React.FC<Props> = React.memo((props) => {
  const { id, children } = props;

  return <div id={id}>{children}</div>;
});
