import React from 'react';
import styled from 'styled-components';

type Props = React.HTMLAttributes<HTMLElement> & {
  width: number | string;
  height: number | string;
  radius?: number;
};

type IconProps = React.HTMLAttributes<HTMLElement> & {
  src: string | React.ReactNode;
  width?: number;
  height?: number;
  radius?: number;
  alt?: string;
};

export const Icon: React.FC<IconProps> = React.memo(({ src, width, height, radius, alt }) => {
  return (
    <>
      {typeof src === 'string' ? (
        <img src={src} className="icon" width={width} height={height} alt={alt} />
      ) : (
        <StyledIcon width={width || '100%'} height={height || '100%'} radius={radius}>
          {src}
        </StyledIcon>
      )}
    </>
  );
});

const StyledIcon = styled.div<Props>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  .icon {
    border-radius: ${(props) => props.radius}px;
  }
`;
