import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { styled } from 'twin.macro';

type Props = {
  size?: 'sm' | 'md' | 'lg';
};
export const Strong: React.FC<Props> = React.memo(({ size = 'lg', children }) => {
  const theme = useContext(ThemeContext);

  switch (size) {
    case 'lg':
      return <StyledSpan fontWeight={theme.fontWeight.bold}>{children}</StyledSpan>;
    case 'md':
      return <StyledSpan fontWeight={theme.fontWeight.regular}>{children}</StyledSpan>;
    default:
      return <StyledSpan fontWeight={theme.fontWeight.light}>{children}</StyledSpan>;
  }
});

type StyledProps = {
  fontWeight: number;
};
const StyledSpan = styled.span<StyledProps>`
  font-weight: ${(props) => props.fontWeight};
`;
