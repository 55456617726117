import React, { useEffect } from 'react';
import { useDispatchStaking } from 'hooks/staking';

export const StakingProvider: React.FC = React.memo(({ children }) => {
  const { memorizedUpdateStakingState } = useDispatchStaking();
  useEffect(() => {
    memorizedUpdateStakingState();
  }, [memorizedUpdateStakingState]);

  return <>{children}</>;
});
