import { BigNumber as BN } from 'bignumber.js';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from 'twin.macro';
import { formToConfirm, setExpectedReward } from 'features/handover';
import { useFscc } from 'hooks';
import { useFetch } from 'hooks/staking/useFetchStaking';
import { useStaking } from 'hooks/staking/useStaking';
import { getCurrentTerm } from 'utils/date';

type Props = React.HTMLAttributes<HTMLElement> & {
  val: string;
  isError?: boolean;
};

export const FormAmountText: React.FC<Props> = React.memo((props) => {
  const dispatch = useDispatch();
  const fetch = useFetch();
  const staking = useStaking();
  const { balance } = useFscc();
  const [amount, setAmount] = useState<string>('0');
  const [inputVal, setInputVal] = useState<string>('0');

  const calcReward = (e: string) => {
    const startTimestamp = fetch.config?.startTimestamp[0];
    if (startTimestamp) {
      const currentTerm = getCurrentTerm(staking.cycle, startTimestamp);
      const nextTermReward = fetch.tokenInfo?.nextTermRewardsList[currentTerm];
      const nextTermStaking = fetch.tokenInfo?.nextTermStakingList[currentTerm];
      if (nextTermReward !== undefined && nextTermStaking !== undefined) {
        dispatch(
          setExpectedReward(
            Math.round(
              Number(
                new BN(e)
                  .times(nextTermReward)
                  .div(nextTermStaking.plus(new BN(e)))
                  .times(10),
              ),
            ) / 10,
          ),
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (inputVal !== props.val) {
      if (isMounted) {
        setAmount(props.val);
        setInputVal(props.val);
        dispatch(formToConfirm(props.val));
      }
      const startTimestamp = fetch.config?.startTimestamp[0];
      if (startTimestamp) {
        const currentTerm = getCurrentTerm(staking.cycle, startTimestamp);
        const nextTermReward = fetch.tokenInfo?.nextTermRewardsList[currentTerm];
        const nextTermStaking = fetch.tokenInfo?.nextTermStakingList[currentTerm];
        if (nextTermReward !== undefined && nextTermStaking !== undefined) {
          if (isMounted) {
            dispatch(
              setExpectedReward(
                Math.floor(
                  Number(
                    new BN(props.val)
                      .times(nextTermReward)
                      .div(nextTermStaking.plus(new BN(props.val))),
                  ),
                ),
              ),
            );
          }
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    inputVal,
    props.val,
    dispatch,
    fetch.config?.startTimestamp,
    fetch.tokenInfo?.nextTermRewardsList,
    fetch.tokenInfo?.nextTermStakingList,
    staking.cycle,
  ]);

  return (
    <StyledDiv>
      <StyledInput
        type="text"
        name="quantity"
        value={amount}
        pattern="^[0-9]+$"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (Number.isFinite(Number(e.target.value)) && Number(e.target.value) != 0) {
            if (new BN(e.target.value).gt(balance)) {
              dispatch(formToConfirm(balance.toString()));
              setAmount(balance.toString());
              calcReward(balance.toString());
            } else {
              dispatch(formToConfirm(Number(e.target.value).toString()));
              setAmount(Number(e.target.value).toString());
              calcReward(e.target.value);
            }
          } else {
            dispatch(formToConfirm('0'));
            setAmount('0');
            dispatch(setExpectedReward(0));
          }
        }}
        {...props}
      />
    </StyledDiv>
  );
});

const StyledDiv = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -ms-flex;
  display: -webkit-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-justify-content: center;
  -webkit-justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 16px;
`;

const StyledInput = styled.input<Props>`
  width: 168px;
  height: 28px;
  text-align: right;
  padding: 8px;
  background-color: ${(props) => (props.isError ? 'pink' : 'white')};
`;
