import React from 'react';
import { styled } from 'twin.macro';

export const Center: React.FC = React.memo(({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
});

const StyledDiv = styled.div`
  text-align: center;
`;
