import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { HeaderTitle, SectionTitle } from 'components/atoms/Heading';
import { SectionList } from 'components/atoms/SectionList';
import { Spacer } from 'components/atoms/Spacer';
import { TextRed } from 'components/atoms/TextRed';
import { Layout } from 'components/layouts';

export const Introduction: React.FC = React.memo(() => {
  const history = useHistory();

  return (
    <Layout id="introduction">
      <Spacer size="md" />
      <HeaderTitle>はじめに</HeaderTitle>
      <Spacer size="md" />
      <Spacer size="lg" />
      <Box>
        <SectionTitle>
          <TextRed>【必ずお読みください】</TextRed>
        </SectionTitle>
        <SectionList>
          <Spacer size="sm" />
          <li>
            FISCOコインレンディングアプリケーションにおいてお客様に付与される貸借料は、各期間において設定される貸借料の総額、全てのお客様の総貸借数及び個々のお客様の貸借数によって算出されます。
          </li>
          <Spacer size="sm" />
          <li>貸借数は100枚からとなります。</li>
          <Spacer size="sm" />
          <li>
            貸出期間は168日間（24週間）とし自動更新となります。
            <br />
            自動更新による貸出の継続を希望しない場合、MyPageにて満期日までにその旨をお申込みください。
          </li>
          <Spacer size="sm" />
          <li>貸出期間中において、貸出を終了することはできません。</li>
          <Spacer size="sm" />
          <li>ご利用の際はお客様個人のMetaMaskウォレットへFISCOコインを移す必要がございます。</li>
          <Spacer size="sm" />
          <li>ご利用にかかるETHはお客様の負担となります。</li>
          <Spacer size="sm" />
          <li>
            本アプリケーション内に記載のある貸借料や貸借料率の表記はお申込時点のものであり、全てのお客様の総貸借数に応じて大きく変動する可能性がございます。
          </li>
          <Spacer size="sm" />
          <li>
            お申込後、ご利用開始日時までに処理が完了しなかった場合、正常にお申込ができない可能性がございます。
          </li>
        </SectionList>
      </Box>
      <Spacer size="lg" />
      <Button onClick={() => history.push('/lending/term-of-service')}>次へ</Button>
    </Layout>
  );
});
