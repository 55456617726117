export const maximumGas = (): number => {
  return 1500000;
};

export const sleep = (ms: number): Promise<unknown> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export * from './decimal';
export * from './format';
export * from './validate';
export * from './promise';
export * from './map';
export * from './date';
