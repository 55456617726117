import { useDispatch } from 'react-redux';
import { TransactionReceipt } from 'web3-core';
import { txSlice } from 'features/tx';

export const useTxHelper = () => {
  const dispatch = useDispatch();
  const { addTransaction } = txSlice.actions;

  const makeTxResponse = (
    description: string,
    errorMsg?: string,
  ): ((txHash: string, receipt?: TransactionReceipt, error?: any) => void) => {
    return (txHash: string, receipt?: TransactionReceipt, error?: any) => {
      const isSuccess = errorMsg || error ? false : true;
      dispatch(
        addTransaction({
          txResponse: {
            data: {
              description: description,
              hash: txHash,
              receipt: receipt,
            },
            isSuccess: isSuccess,
            error: isSuccess ? undefined : new Error(errorMsg || error),
          },
        }),
      );
    };
  };

  return { makeTxResponse };
};
