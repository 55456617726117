import React from 'react';
import { useSelector } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useWallet, UseWalletProvider } from 'use-wallet';
import { Loading } from 'components/atoms/Loading';
import { ScrollToTop } from 'components/scroll/ScrollToTop';
import { RootState } from 'features/reducer';
import {
  Announcement,
  ApplicationConfirmation,
  Applied,
  Applying,
  ConfirmationContractDetail,
  Form,
  Index,
  IndexStop,
  Introduction,
  MyPage,
  Sorry,
  Term,
  ContractError,
  // LandingPage,
} from 'pages';
import { NoEnvironment } from 'pages/NoEnvironment';
import { NotFound } from 'pages/NotFound';
import { ModalProvider } from 'provider';
import FiscoSdkProvider from 'provider/Fisco';
import { SettingProvider } from 'provider/Setting';
import { StakingProvider } from 'provider/Staking';
import { chainID } from 'resource/constant';
import theme from 'theme';

const App = (): JSX.Element => {
  const loading = useSelector((state: RootState) => state.handover.loading);

  return (
    <Providers>
      <Router>
        <ScrollToTop />
        <Loading src="/lending/ajax-loader.gif" isLoading={loading} />
        <Switch>
          <Route path="/lending" exact>
            <Index />
          </Route>
          <Route path="/lending/no-environment" exact>
            <NoEnvironment />
          </Route>
          <Route path="/lending/announcement" exact>
            <Announcement />
          </Route>
          <Route path="/lending/application-confirmation" exact>
            <ApplicationConfirmation />
          </Route>
          <Route path="/lending/applied" exact>
            <Applied />
          </Route>
          <Route path="/lending/applying" exact>
            <Applying />
          </Route>
          <Route path="/lending/confirmation-contract-details" exact>
            <ConfirmationContractDetail />
          </Route>
          <Route path="/lending/mypage" exact>
            <MyPage />
          </Route>
          <Route path="/lending/form" exact>
            <Form />
          </Route>
          <Route path="/lending/index-stop" exact>
            <IndexStop />
          </Route>
          <Route path="/lending/introduction" exact>
            <Introduction />
          </Route>
          <Route path="/lending/sorry" exact>
            <Sorry />
          </Route>
          <Route path="/lending/term-of-service" exact>
            <Term />
          </Route>
          <Route path="/lending/contract-error" exact>
            <ContractError />
          </Route>
          {/* <Route path="/lending" exact>
            <LandingPage />
          </Route> */}
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </Providers>
  );
};

const Providers: React.FC = React.memo(({ children }) => {
  return (
    <SettingProvider>
      <ThemeProvider theme={theme}>
        <UseWalletProvider chainId={chainID}>
          <AutoConnectProvider>
            <FiscoSdkProvider>
              <StakingProvider>
                <ModalProvider>{children}</ModalProvider>
              </StakingProvider>
            </FiscoSdkProvider>
          </AutoConnectProvider>
        </UseWalletProvider>
      </ThemeProvider>
    </SettingProvider>
  );
});

const AutoConnectProvider: React.FC = ({ children }) => {
  const { connect, status } = useWallet();

  switch (status) {
    case 'disconnected':
      // eslint-disable-next-line
      (async () => {
        await connect('injected');
      })();
      break;
  }

  return <>{children}</>;
};

export default App;
