import React from 'react';
import styled from 'styled-components';

type Props = React.HTMLAttributes<HTMLElement> & {
  onChange: any;
};

export const CheckBox: React.FC<Props> = React.memo(({ onChange }) => {
  return (
    <>
      <StyledInput type="checkbox" name="checkbox" id="agree" onChange={onChange} />
      <label htmlFor="agree">&nbsp;</label>
    </>
  );
});

const StyledInput = styled.input`
  display: none;

  & + label {
    padding-left: 32px;
    position: relative;
    margin-right: 20px;
    line-height: 32px;
  }

  & + label::before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    left: 0;
    width: 24px;
    height: 24px;
    border: 1px solid ${(props) => props.theme.color.grey[600]};
  }

  & + label span {
    font-size: 30px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    font-weight: 400;
  }

  @media (max-width: 959px) {
    & + label span {
      font-size: 30px;
      line-height: 32px;
    }
  }

  &:checked + label::after {
    content: '';
    display: block;
    position: absolute;
    top: -14px;
    left: 5px;
    width: 16px;
    height: 28px;
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
    border-bottom: 5px solid ${(props) => props.theme.color.darkBlue};
    border-right: 5px solid ${(props) => props.theme.color.darkBlue};
    border-bottom-right-radius: 32%;
  }
`;
