import React from 'react';
import { styled } from 'twin.macro';

export const SectionSubTitle: React.FC = React.memo(({ children }) => {
  return <StyledH3>{children}</StyledH3>;
});

const StyledH3 = styled.h3`
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  text-align: left;

  #application-confirmation & {
    font-weight: 400;
    text-align: center;
  }
`;
