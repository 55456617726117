import React from 'react';
import { styled } from 'twin.macro';

type Props = { applied?: boolean };

export const BoxTerm: React.FC<Props> = React.memo((props) => {
  const { children, applied } = props;

  return <StyledSectionTerm applied={applied}>{children}</StyledSectionTerm>;
});

const StyledSectionTerm = styled.section<Props>`
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.color.grey[700]};
  padding: 16px;
  overflow-y: scroll;
  width: 100%;
  background-color: ${(props) => (props.applied ? props.theme.color.sectionBG : '')};
  height: 550px;

  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    padding: 16px;
  }
  #applied & {
    padding: 32px 0;
  }
`;
