import { BigNumber as BN } from 'bignumber.js';
import { useSelector } from 'react-redux';
import { RootState } from 'features/reducer';
import { useTxHelper } from 'hooks';

export const useTxStaking = () => {
  const { fisco } = useSelector((state: RootState) => state.sdk);
  const { makeTxResponse } = useTxHelper();

  const description = 'staking';

  const stake = async (amount: BN): Promise<boolean> => {
    try {
      if (!fisco) throw new Error('undefined sdk');

      return fisco.send(await fisco.stakingAggregator.stake(amount), {
        onTxHash: makeTxResponse(description),
      });
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  const withdraw = async (amount: BN): Promise<boolean> => {
    try {
      if (!fisco) throw new Error('undefined sdk');

      return fisco.send(await fisco?.stakingAggregator.withdraw(amount), {
        onTxHash: makeTxResponse(`${description} withdraw`),
      });
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  const receiveReward = async (): Promise<boolean> => {
    try {
      if (!fisco) throw new Error('undefined sdk');

      return fisco.send(await fisco?.stakingAggregator.receiveReward(), {
        onTxHash: makeTxResponse(`${description} receiveReward`),
      });
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  return { stake, withdraw, receiveReward };
};
