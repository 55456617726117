// index.html
import React from 'react';
import { useHistory } from 'react-router-dom';
import { styled } from 'twin.macro';
import { HeaderTitle } from 'components/atoms/Heading';
import { Spacer } from 'components/atoms/Spacer';
import { Layout } from 'components/layouts';
import { WalletConnectButton } from 'components/wallet/ConnectButton';

export const NoEnvironment: React.FC = React.memo(() => {
  const history = useHistory();

  return (
    <Layout id="index">
      <Spacer size="md" />
      <HeaderTitle>Sorry</HeaderTitle>
      <Spacer size="md" />
      <Spacer size="lg" />
      <StyledDivWrap>
        <StyledSectionEnvironment id="metamask">
          <StyledHeaderReadMe>
            ※必ずお読みください。
            <br />
            ご利用には下記の環境が必須となります。
          </StyledHeaderReadMe>
          <StyledDivReadMe>
            ▼ご利用環境
            <ul>
              <li>・Chromeブラウザ</li>
              <li>・MetaMaskアプリ</li>
            </ul>
            <a
              target="_blank"
              href="https://fiscocoin.com/lending/introduction.html"
              rel="noreferrer"
            >
              設定方法
            </a>
          </StyledDivReadMe>
        </StyledSectionEnvironment>
        <Spacer size="lg" />
        <WalletConnectButton
          onClick={() => {
            history.push('/lending/mypage');
          }}
        />
      </StyledDivWrap>
    </Layout>
  );
});
const StyledDivWrap = styled.div`
  width: 496px;
  margin: 0 auto;
  padding-bottom: 48px;

  @media (max-width: 959px) {
    width: 100%;
  }
`;

const StyledSectionEnvironment = styled.section`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid #e0e0e0;
  padding-top: 16px;

  @media (max-width: 959px) {
    width: calc(100% - 32px);
    margin: 0 auto;
    margin-top: 16px;
    padding: 16px;
  }
`;

const StyledHeaderReadMe = styled.h2`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  color: #f44336;
  margin-top: 0px;
  text-align: center;
`;

const StyledDivReadMe = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  margin-top: 8px;
  margin-top: 16px;
  text-align: center;

  li {
    font-weight: 600;
    margin-top: 8px;
    list-style: none;
  }

  a {
    line-height: calc(48px - 2px);
    height: 48px;
    border-radius: 0;
    display: block;
    margin-top: 16px;
    border: 1px solid #0069c0;
    width: 300px;
    margin: 16px auto;
    text-decoration: none;
    opacity: 1;
  }

  a:hover,
  a:active {
    color: #424242;
    color: #9e9e9e;
    font-weight: 600;
    text-decoration: underline;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.75;
  }

  a:link,
  a:visited {
    color: #424242;
    font-weight: 600;
    text-decoration: none;
  }

  a::after {
    border-top: 2px solid #424242;
    border-right: 2px solid #424242;
  }
`;
