import React from 'react';
import { styled } from 'twin.macro';

export const HeaderTitle: React.FC = React.memo(({ children }) => {
  return <StyledH1>{children}</StyledH1>;
});

const StyledH1 = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  text-align: center;

  @media (max-width: 959px) {
    font-size: 22px;
    line-height: 24px;
  }

  #index & {
    font-size: 30px;
    @media (max-width: 959px) {
      font-size: 30px;
      line-height: 32px;
    }
  }
`;
