import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from 'twin.macro';
import { Box, BoxBg } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { FlexBox } from 'components/atoms/FlexBox';
import { HeaderTitle, SectionTitle } from 'components/atoms/Heading';
import { FsccIcon } from 'components/atoms/Icon';
import { Meter } from 'components/atoms/Meter';
import { Spacer } from 'components/atoms/Spacer';
import { Value } from 'components/atoms/Value';
import { ContractMaturityLabel } from 'components/datetime/ContractMaturityLabel';
import { Layout } from 'components/layouts';
import { RateRadio } from 'components/rateradio/RateRadio';
import { RootState } from 'features/reducer';
import { useFscc } from 'hooks';

export const Form: React.FC = React.memo(() => {
  const { balance } = useFscc();
  const history = useHistory();
  const amount = useSelector((state: RootState) => state.handover.amount);
  const expectedReward = useSelector((state: RootState) => state.handover.expectedReward);
  const [errorMessage, setErrorMessage] = useState<string>('');

  return (
    <Layout id="application">
      <Spacer size="md" />
      <HeaderTitle>お申込みフォーム</HeaderTitle>
      <StyledDiv>※入力項目は必須です</StyledDiv>
      <Spacer size="md" />
      <ul>
        <ErrorLi>{errorMessage}</ErrorLi>
      </ul>
      <Spacer size="md" />
      <Meter step="form" />
      <Spacer size="sm" />

      <SectionTitle>お申込内容</SectionTitle>
      <Spacer size="lg" />

      <SectionTitle>貸出期間</SectionTitle>
      <Spacer size="xs" />
      <FlexBox>
        <Value value="168日間コース" position="center"></Value>
      </FlexBox>

      <Spacer size="lg" />

      <Box>
        <PaddingBox>
          <SectionTitle>保有数</SectionTitle>
          <Spacer size="sm" />
          <FlexBox>
            <FlexBox column>
              <FsccIcon />
              <StyledFSCC>FSCC</StyledFSCC>
            </FlexBox>
            <Value value={balance} unit="枚" />
          </FlexBox>
          <Spacer size="sm" />

          <SectionTitle>貸借数</SectionTitle>
          <RateRadio isError={errorMessage != ''} />
        </PaddingBox>
        <Spacer size="sm" />

        <BoxBg>
          <p>貸借料（予定）</p>
          <FlexBox>
            <Value value={expectedReward.toString()} unit="枚" />
          </FlexBox>

          <Spacer size="xs" />

          <p>満期日</p>
          <FlexBox>
            <ContractMaturityLabel />
          </FlexBox>
        </BoxBg>
      </Box>

      <Spacer size="lg" />
      <Button
        onClick={() => {
          if (amount < 100) {
            setErrorMessage('貸出数は100枚以上にて設定下さい。');
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          } else {
            history.push('/lending/application-confirmation');
          }
        }}
      >
        次へ
      </Button>
    </Layout>
  );
});

const StyledDiv = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
  color: #f44336;
  text-align: center;
  margin-top: 8px;
`;

const ErrorLi = styled.li`
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  text-align: center;
  color: #f44336;
  margin-top: 8px;
  list-style: none;
`;

const PaddingBox = styled.div`
  box-sizing: content-box;
  padding-left: 8px;
  padding-right: 8px;
`;

const StyledFSCC = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
`;
