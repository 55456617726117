import { enableMapSet } from 'immer';
import { combineReducers } from 'redux';
import { fsccSlice } from './fscc';
import { handoverSlice } from './handover';
import { modalSlice } from './modal';
import { sdkSlice } from './sdk';
import { settingSlice } from './setting';
import { stakingSlice } from './staking';
import { txSlice } from './tx';

enableMapSet();

export const rootReducer = combineReducers({
  modal: modalSlice.reducer,
  setting: settingSlice.reducer,
  sdk: sdkSlice.reducer,
  fscc: fsccSlice.reducer,
  tx: txSlice.reducer,
  staking: stakingSlice.reducer,
  handover: handoverSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
