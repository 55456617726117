import { useButton } from '@react-aria/button';
import { AriaButtonProps } from '@react-types/button'; //eslint-disable-line
import React, { useRef } from 'react';
import styled from 'styled-components';

type Props = AriaButtonProps & {
  onClick?: () => void;
};

export const CancelButton: React.FC<Props> = React.memo((props) => {
  const ref = useRef(null);
  const { buttonProps } = useButton(props, ref);
  const { children } = props;

  return (
    <StyledButton {...buttonProps} ref={ref} onClick={props.onClick}>
      {children}
    </StyledButton>
  );
});

const StyledButton = styled.button`
  font-size: 18px;
  font-weight: 600;
  line-height: calc(48px - 2px);
  height: 48px;
  width: 300px;
  border-radius: 20px;
  border: 1px solid black;
  background-color: #fff;
  color: black;
  display: inline-block;
  text-align: center;
  padding: 0;
  position: relative;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  transition: 0.2s linear;

  &:hover {
    color: #0069c0;
    border: 1px solid #0069c0;
    background-color: #fff;
    cursor: pointer;
  }

  &:active,
  &:focus {
    box-shadow: none;
    box-shadow: 1px 1px 2px #424242 inset;
    background-color: #fff;
    color: #0069c0;
    border: 1px solid #0069c0;
    outline: none;
  }

  &active::after,
  &:focus::after {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  &:disabled {
    background-color: #bdbdbd;
    color: #616161;
    border-color: #bdbdbd;
    box-shadow: none;
    opacity: 1;
    cursor: inherit;
  }
`;

// const StyledButton = styled.button`
//   font-size: 14px;
//   font-weight: 600;
//   line-height: calc(48px - 2px);
//   height: 48px;
//   /* width: 300px; */
//   width: 90%;
//   border-radius: 0;
//   color: ${(props) => props.theme.color.darkBlue};
//   border: 1px solid ${(props) => props.theme.color.darkBlue};
//   background-color: ${(props) => props.theme.color.white};
//   display: inline-block;
//   text-align: center;
//   padding: 0;
//   position: relative;
//   display: block;
//   margin: 0 auto;
//   overflow: hidden;
//   /* z-index: 1; */
//   -webkit-transition: 0.2s linear;
//   transition: 0.2s linear;

//   :hover {
//     border: 1px solid ${(props) => props.theme.color.darkBlue};
//     background-color: ${(props) => props.theme.color.darkBlue};
//     color: ${(props) => props.theme.color.white};
//     cursor: pointer;
//   }

//   &:active {
//     -webkit-box-shadow: none;
//     box-shadow: none;
//     -webkit-box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
//     box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
//     background-color: ${(props) => props.theme.color.white};
//     color: ${(props) => props.theme.color.darkBlue};
//     border: 1px solid ${(props) => props.theme.color.darkBlue};
//     outline: none;
//   }
//   &:focus　 {
//     -webkit-box-shadow: none;
//     box-shadow: none;
//     -webkit-box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
//     box-shadow: 1px 1px 2px ${(props) => props.theme.color.grey[200]} inset;
//     background-color: ${(props) => props.theme.color.white};
//     color: ${(props) => props.theme.color.darkBlue};
//     border: 1px solid ${(props) => props.theme.color.darkBlue};
//     outline: none;
//   }

//   &:active::after {
//     border-top: 2px solid ${(props) => props.theme.color.white};
//     border-right: 2px solid ${(props) => props.theme.color.white};
//   }

//   &:focus::after {
//     border-top: 2px solid ${(props) => props.theme.color.white};
//     border-right: 2px solid ${(props) => props.theme.color.white};
//   }

//   &:disabled {
//     background-color: ${(props) => props.theme.color.grey[500]};
//     color: ${(props) => props.theme.color.grey[300]};
//     border-color: ${(props) => props.theme.color.grey[500]};
//     -webkit-box-shadow: none;
//     box-shadow: none;
//     opacity: 1;
//     cursor: inherit;
//   }
// `;
