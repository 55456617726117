import React from 'react';
import { styled } from 'twin.macro';

export const TextRed: React.FC = React.memo(({ children }) => {
  return <StyledWrapper>{children}</StyledWrapper>;
});

export const StyledWrapper = styled.span`
  color: ${(props) => props.theme.color.red};
`;
