import React from 'react';
import { Anchor } from 'components/atoms/Anchor';
import { Box } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { Center } from 'components/atoms/Center';
import { HeaderTitle } from 'components/atoms/Heading';
import { Spacer } from 'components/atoms/Spacer';
import { Layout } from 'components/layouts';

export const NotFound: React.FC = React.memo(() => {
  return (
    <Layout id="not-found">
      <Spacer size="md" />
      <HeaderTitle>404エラー</HeaderTitle>
      <Spacer size="lg" />
      <Box>
        <Center>ページが見つかりません。</Center>
      </Box>
      <Spacer size="lg" />
      <Anchor to="/lending">
        <Button>トップページ</Button>
      </Anchor>
    </Layout>
  );
});
