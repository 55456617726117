const mainnet = 1;
const ropsten = 3;
export const chainID = 1;

export const contractAddresses: {
  [contract: string]: {
    [networkId: number]: string;
  };
} = {
  fiscoCoin: {
    [mainnet]: '0x0789dbAE94fb18e5789B8e4489BCB7A1ADB58622',
    [ropsten]: '0xBf144182246702BB62b0218730bdA90259F00D23',
  },
  stakingAggregator: {
    [mainnet]: '0x44Fae3e17B412e5EC1bD32CFE44DFd6E57366109',
    [ropsten]: '0x645b981Bf8aFbb41F88F4ae941dBc9bcc3A03bE3',
  },
};

const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
export const WEB3_PROVIDER_URL: {
  [networkId: number]: string;
} = {
  [mainnet]: `https://mainnet.infura.io/v3/${INFURA_PROJECT_ID}`,
  [ropsten]: `https://ropsten.infura.io/v3/${INFURA_PROJECT_ID}`,
};
