/* eslint-disable @typescript-eslint/no-floating-promises */
import axios from 'axios';
import { BigNumber as BN } from 'bignumber.js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from 'components/atoms/Box';
import { FlexBox } from 'components/atoms/FlexBox';
import { Footer } from 'components/atoms/Footer';
import { HeaderTitle, SectionTitle } from 'components/atoms/Heading';
import { Right } from 'components/atoms/Right';
import { Spacer } from 'components/atoms/Spacer';
import { Value } from 'components/atoms/Value';
import { SimpleValue } from 'components/atoms/Value/SimpleValue';
import { DateTimeLabel } from 'components/datetime/DateTimeLabel';
import { Layout } from 'components/layouts';
import { LendingConfirmModal } from 'components/modal';
import { useModal } from 'hooks';
import { useFetch } from 'hooks/staking/useFetchStaking';
import { useStaking } from 'hooks/staking/useStaking';
import { getCurrentTerm } from 'utils';

export const MyPage: React.FC = React.memo(() => {
  const staking = useStaking();
  const history = useHistory();
  const fetch = useFetch();

  const [openModal, _] = useModal(
    <LendingConfirmModal
      onClick={() => {
        history.push('/lending/introduction');
      }}
    />,
  );

  const [datetime, setDatetime] = useState<string>('');
  useEffect(() => {
    const fetchDateTime = async () => {
      const result = await axios.head('https://fiscocoin.com/lending');
      // const result = await axios.head('https://test-lending.f5.si/lending');
      // const result = await axios.head('http://localhost:3000/lending');
      setDatetime(result.headers.date);
    };
    fetchDateTime();
  }, []);

  // 総貸出量
  let totalLending = staking.withdrawableStakingAmount;
  if (fetch.accountInfo && fetch.accountInfo?.nextAddedStakeAmountList) {
    fetch.accountInfo?.nextAddedStakeAmountList.forEach((item) => {
      if (item.lt(0)) {
        totalLending = totalLending.plus(new BN(Math.abs(Number(item))));
      }
    });
  }

  // 総利息数
  let totalReward = new BN(0);

  // 旧計算式
  // if (fetch.accountInfo && fetch.accountInfo.withdrawableStakingAmountList) {
  //   fetch.accountInfo.withdrawableStakingAmountList.forEach((item, index) => {
  //     if (!item.eq(0)) {
  //       if (
  //         fetch.tokenInfo &&
  //         fetch.tokenInfo.nextTermRewardsList &&
  //         fetch.tokenInfo.nextTermStakingList &&
  //         fetch.tokenInfo.currentTermRewardsList &&
  //         fetch.tokenInfo.currentStakingList
  //       ) {
  //         const nextTermReward = fetch.tokenInfo.nextTermRewardsList[index];
  //         const nextTermStaking = fetch.tokenInfo.nextTermStakingList[index];
  //         const currentTermReward = fetch.tokenInfo.currentTermRewardsList[index];
  //         const currentStaking = fetch.tokenInfo.currentStakingList[index];

  //         if (nextTermReward.eq(0)) {
  //           if (currentStaking.eq(0)) {
  //             totalReward += 0;
  //           } else {
  //             totalReward +=
  //               Math.round(Number(item.times(currentTermReward).div(currentStaking).times(10))) /
  //               10;
  //           }
  //         } else {
  //           // nextTermRewardがあり、かつuserTermとlatestTermがズレている場合、利息追加により
  //           if (nextTermStaking.eq(0)) {
  //             totalReward += 0;
  //           } else {
  //             totalReward +=
  //               Math.round(Number(item.times(nextTermReward).div(nextTermStaking).times(10))) / 10;
  //           }
  //         }
  //       }
  //     }
  //   });
  // }
  if (fetch.accountInfo && fetch.accountInfo.nextAddedStakeAmountList) {
    fetch.accountInfo.nextAddedStakeAmountList.forEach((item, index) => {
      if (!item.eq(0)) {
        if (
          fetch.accountInfo?.nextTermUserRewardsList &&
          fetch.accountInfo?.currentTermUserRewardsList &&
          fetch.accountInfo?.userTermList &&
          fetch.tokenInfo &&
          fetch.tokenInfo.latestTermList &&
          fetch.config &&
          fetch.config.startTimestamp
        ) {
          const nextTermUserReward = fetch.accountInfo?.nextTermUserRewardsList[index];
          const currentTermUserReward = fetch.accountInfo?.currentTermUserRewardsList[index];
          const userTerm = fetch.accountInfo?.userTermList[index];
          const latestTerm = fetch.tokenInfo.latestTermList[index];

          // 今どの申込タームにいるか
          const currentTerm = getCurrentTerm(staking.cycle, fetch.config?.startTimestamp[0]);

          if (item.lt(0)) {
            // if (currentTermUserReward.eq(0)) {
            //   totalReward = totalReward.plus(nextTermUserReward);
            // } else {
            totalReward = totalReward.plus(currentTermUserReward);
            // }
          } else if (nextTermUserReward.eq(0)) {
            if (userTerm == latestTerm) {
              totalReward = totalReward.plus(currentTermUserReward);
            } else if (item.gt(0)) {
              totalReward = totalReward.plus(currentTermUserReward);
            }
          } else {
            if (currentTerm == index && userTerm != latestTerm) {
              totalReward = totalReward.plus(currentTermUserReward);
            } else if (nextTermUserReward.eq(0)) {
              totalReward = totalReward.plus(currentTermUserReward);
            } else {
              totalReward = totalReward.plus(nextTermUserReward);
            }
          }
        }
      } else if (
        fetch.accountInfo?.withdrawableStakingAmountList &&
        fetch.accountInfo?.withdrawableStakingAmountList[index].gt(0) &&
        fetch.accountInfo?.nextTermUserRewardsList &&
        fetch.accountInfo?.currentTermUserRewardsList &&
        fetch.accountInfo?.userTermList &&
        fetch.tokenInfo &&
        fetch.tokenInfo.latestTermList &&
        fetch.config &&
        fetch.config.startTimestamp
      ) {
        const nextTermUserReward = fetch.accountInfo?.nextTermUserRewardsList[index];
        const currentTermUserReward = fetch.accountInfo?.currentTermUserRewardsList[index];
        const userTerm = fetch.accountInfo?.userTermList[index];
        const latestTerm = fetch.tokenInfo.latestTermList[index];

        // 今どの申込タームにいるか
        const currentTerm = getCurrentTerm(staking.cycle, fetch.config?.startTimestamp[0]);

        if (nextTermUserReward.eq(0)) {
          totalReward = totalReward.plus(currentTermUserReward);
        } else {
          if (currentTerm == index && userTerm != latestTerm) {
            totalReward = totalReward.plus(currentTermUserReward);
          } else if (userTerm == latestTerm) {
            if (currentTermUserReward.eq(0)) {
              totalReward = totalReward.plus(nextTermUserReward);
            } else {
              totalReward = totalReward.plus(currentTermUserReward);
            }
          } else {
            totalReward = totalReward.plus(nextTermUserReward);
          }
        }
      }
    });
  }

  // 総利息率
  let totalLendingRate = '0.00';
  if (totalReward.gt(0) && totalLending.gt(0)) {
    totalLendingRate = (
      Math.round(Number(totalReward.dividedBy(totalLending).times(1000))) / 10
    ).toString();
  }

  return (
    <>
      <Layout id="mypage">
        <Spacer size="md" />
        <HeaderTitle>MyPage</HeaderTitle>
        <Spacer size="md" />
        <Spacer size="lg" />

        <Box>
          <SectionTitle>資産情報</SectionTitle>
          <Spacer size="xs" />
          <FlexBox>
            <Right>
              (<DateTimeLabel date={new Date(datetime)} monthOnly={false} /> 現在)
            </Right>
          </FlexBox>
          <Spacer size="sm" />
          <FlexBox>
            <FlexBox column>
              {/* <SectionTitle>総貸出数</SectionTitle> */}
              <SectionTitle>貸借数</SectionTitle>
            </FlexBox>
            <SimpleValue value={totalLending.toString()} unit="枚" />
          </FlexBox>
          <Spacer size="xs" />
          <FlexBox>
            {/* <SectionTitle>総利息数</SectionTitle> */}
            <SectionTitle>貸借料</SectionTitle>
            <Value value={(Math.round(Number(totalReward.times(10))) / 10).toString()} unit="枚" />
          </FlexBox>
          <Spacer size="xs" />
          <FlexBox>
            <SectionTitle>貸借料率</SectionTitle>
            <Value value={totalLendingRate} unit="%" />
          </FlexBox>
        </Box>
        <StyledSection>
          <StyledLiAttention>
            ※貸借料、貸借料率は本日時点での予定となり、全てのお客様の総貸借数に応じて変動します
          </StyledLiAttention>
        </StyledSection>
      </Layout>
      <Footer onClick={() => openModal()} />
    </>
  );
});

const StyledSection = styled.ul`
  list-style-type: disc;
`;

const StyledLiAttention = styled.li`
  list-style: none;
  font-size: 80%;
  text-align: left;
  padding-top: 5px;
  padding-right: 5px;
`;
