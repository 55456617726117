import { BigNumber as BN } from 'bignumber.js';
import { useSelector } from 'react-redux';
import { RootState } from 'features/reducer';
import { useTxHelper } from 'hooks';

export const useTxFscc = () => {
  const { fisco } = useSelector((state: RootState) => state.sdk);
  const { makeTxResponse } = useTxHelper();

  const description = 'fscc transaction';

  const approve = async (amount: BN): Promise<boolean> => {
    try {
      if (!fisco) throw new Error('undefined sdk');

      return fisco.send(await fisco.fscc.approve(fisco.stakingAggregator.address, amount), {
        onTxHash: makeTxResponse(description),
      });
    } catch (e) {
      console.error(e);
    }

    return false;
  };

  return { approve };
};
