import React from 'react';
import { styled } from 'twin.macro';
import { FlexBox } from '../FlexBox';
import { Icon } from './Icon';

type Props = {
  label?: boolean;
};

export const FsccIcon: React.FC<Props> = React.memo(({ label }) => (
  <FlexBox column>
    <Icon src="/lending/fscc_icon.png" alt="fscc" />
    {label ? <StyledLabel>FSCC</StyledLabel> : <></>}
  </FlexBox>
));

const StyledLabel = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-align: left;
`;
