import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BoxTerm } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { Center, CenterBold } from 'components/atoms/Center';
import { CheckBox } from 'components/atoms/CheckBox';
import { HeaderTitle } from 'components/atoms/Heading';
import { Spacer } from 'components/atoms/Spacer';
import { TextIndentOne } from 'components/atoms/Text';
import { TextIndentTwo } from 'components/atoms/Text/TextIndentTwo';
import { Layout } from 'components/layouts';

export const Term: React.FC = React.memo(() => {
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const history = useHistory();

  return (
    <Layout id="terms-of-service">
      <Spacer size="md" />
      <HeaderTitle>利用規約</HeaderTitle>
      <Spacer size="md" />
      <Spacer size="lg" />
      <BoxTerm>
        <CenterBold>レンディングアプリケーション利用規約</CenterBold>
        <br />
        「レンディングアプリケーション利用規約」（以下「本規約」といいます。）は、株式会社FISCO
        Decentralized Application
        Platform（以下「当社」といいます。）が提供する本アプリ（第2条で定義されます。）の利用条件を定めるものです。本アプリを利用される前に、本規約を必ずお読みください。
        <br />
        <br />
        <br />
        <CenterBold>第1条 目的及び適用</CenterBold>
        <br />
        <TextIndentOne>
          1.本規約は、本アプリの利用に関する当社とお客さまとの間の本件サービスに関する権利義務を定めることを目的とし、当社とお客さまとの間の本アプリの利用に関わる一切の関係に適用されます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          2.本規約のほか、本アプリの利用について、当社が利用条件やガイドライン、ポリシー、説明事項、注意事項等を定める場合、本規約の一部を構成するものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第2条 定義</CenterBold>
        <br />
        次の各号に掲げる用語の意義は、当該各号に定める通りとします。
        <br />
        <br />
        <TextIndentOne>
          ①「本アプリ」とは、当社がウェブサイト上で提供する、本機能に係るスマートコントラクトを実装するアプリケーションで、その名称を「FISCOコインレンディングアプリケーション」とするものをいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ②「貸出（貸し出し）」とは、本アプリのスマートコントラクトアドレスに対象暗号資産を送付することによって、当該対象暗号資産をスマートコントラクト上にロックすることをいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ③「本機能」とは、本アプリのスマートコントラクトを用いて、お客さま自身の単独行為によって本アプリ上で対象暗号資産の貸出を行うことができ、かつ当該貸出期間の満了時にお客さまに対象暗号資産の返還及び貸借料の付与がなされる仕組み又は機能をいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ④「お客さまウォレット」とは、第5条に定めるMetaMaskウォレットをいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑤「対象暗号資産」とは、本機能の対象となる暗号資産としてフィスココインをいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>⑥「貸出期間」とは、第7条第3項に定める貸出期間をいいます。</TextIndentOne>
        <br />
        <TextIndentOne>
          ⑦「貸出開始日」とは、第7条第1項に従い別途公表される貸出期間の開始日をいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>⑧「満期日」とは、第7条第3項に定める満期日をいいます。</TextIndentOne>
        <br />
        <TextIndentOne>
          ⑨「貸出数量」とは、お客さまが第7条に基づきお客さまが貸し出した対象暗号資産の数量をいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑩「貸借料」とは、お客さまが対象暗号資産を貸借期間中貸し出すことによって付与される経済的利益をいい、第7条第5項及び第6項に定める通り算出及び付与されるものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑪「反社会的勢力」とは、次のaからfのいずれかに該当する者をいいます。
        </TextIndentOne>
        <br />
        <TextIndentTwo>
          a
          暴力団、暴力団員、暴力団員でなくなった日から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋、社会運動等標ぼうゴロ若しくは特殊知能暴力集団等、又はこれらに準ずる者（これらを総称して、以下「暴力団員等」といいます。）
        </TextIndentTwo>
        <br />
        <TextIndentTwo>b 暴力団員等が経営を支配していると認められる関係を有する者</TextIndentTwo>
        <br />
        <TextIndentTwo>
          c 暴力団員等が経営に実質的に関与していると認められる関係を有する者
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          d
          自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等不当に暴力団員等を利用していると認められる関係を有する者
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          e
          暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有する者
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          f 役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有する者
        </TextIndentTwo>
        <br />
        <TextIndentOne>
          ⑫「反社会的行為」とは、a暴力的な要求行為、b法的な責任を超えた不当な要求行為、c取引に関して、脅迫的な言動をし、若しくは暴力を用いる行為、d風説を流布し、偽計を用い若しくは威力を用いて当社の信用を毀損し若しくは当社の業務を妨害する行為、又はeその他これらに準ずる行為をいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑬「利用契約」とは、第5条に基づき当社とお客さまの間で成立する、本規約の諸規定に従った本アプリの利用に係る契約をいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑭「当社ウェブサイト」とは、そのドメインが「fiscocoin.com」である、当社が運営するウェブサイト（サブドメインを含み、また、理由を問わず当社のウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含みます。）をいいます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑮「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（これらの権利を取得し、又はこれらの権利につき登録等を出願する権利を含みます。）をいいます。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第3条 本アプリの利用にあたっての同意事項</CenterBold>
        <br />
        本アプリの利用を希望するお客さまは、本アプリ及び本機能に関する次の各号に記載する事項をあらかじめ確認し、その内容を理解しかつ許容することができる場合に限り、ご自身の責任と判断において本アプリの利用を行うものとします。
        <br />
        <br />
        <TextIndentOne>
          ①本機能は、お客さま及び当社との間で対象暗号資産について消費貸借契約その他取引に係る一切の契約を成立させるものではないこと。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ②本機能は、預金又は預金に類似する商品ではなく、預金保険の対象外であること。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ③暗号資産の価格は常に変動しているため、お客さまが本機能の利用により貸し出した対象暗号資産も常に価格変動するリスクがあるとともに、貸し出された時点よりも対象暗号資産の価格が下落することにより、お客さまに損失が生じるおそれがあること。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ④本機能は、資金決済法第2条第7項各号に掲げる行為（暗号資産交換業）には該当しないこと。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑤本機能の利用により貸し出された対象暗号資産は、当社において、資金決済法第63条の11第2項に規定される分別管理の対象とはならず、お客さまは当社に対し、同法第63条の19の2第1項に規定される権利を有していないこと。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑥本アプリの仕様上、貸出期間中は、いかなる事由によっても対象暗号資産の貸出の中止等はできないこと。また、お客さまは、貸出期間中、対象暗号資産の返還を受けることができないこと。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑦当社が破たんし本アプリの提供ができなくなった場合、対象暗号資産の移転の仕組みが破たんした場合等、お客さまが貸し出した対象暗号資産が返還されないリスクがあること。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑧お客さまは、自己の責任により、本アプリを利用すること。また、お客さまは、本アプリにおける自らの行為について、一切の責任を負うこと。
        </TextIndentOne>
        <br />
        <TextIndentOne>⑨当社はお客さま同士のやり取りには関与しないこと。 </TextIndentOne>
        <br />
        <TextIndentOne>
          ⑩お客さまが反社会的勢力に該当する場合、本アプリを利用できないこと。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第4条 利用環境等の整備</CenterBold>
        <br />
        <TextIndentOne>
          1.お客さまは、自己の費用と責任において、本アプリを利用するために必要なコンピューター、電気通信設備その他の機器、及びその他必要なソフトウェア（総称して、以下「お客さま設備」といいます。）を用意しこれらをインターネットに接続するほか、ConsenSys
          Software
          Inc.が提供するMetaMaskウォレット（以下「MetaMaskウォレット」といいます。）をインストールするものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          2.お客さまは、自己の費用と責任において、当社が別途定める本アプリの利用環境を整備するものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          3.お客さま設備、そのインターネットへの接続、MetaMaskウォレット又は本アプリの利用環境に不具合がある場合には、当社はお客さまに対して、本アプリを提供する義務を負わないものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          4.第1項及び第2項に定めるお客さま設備や利用環境等を整備せずに本アプリを利用した結果、お客さまに損害が生じた場合でも、当社は、当該損害について責任を負わないものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第5条 利用契約の成立</CenterBold>
        <br />
        お客さまが本規約に同意し、かつ前条第1項においてインストールしたMetaMaskウォレットを本アプリに連携した時点で（当該連携したMetaMaskウォレットを、以下「お客さまウォレット」といいます。）、本規約に従った本アプリに係る利用契約が成立するものとします。
        <br />
        <br />
        <br />
        <CenterBold>第6条 利用料その他手数料等</CenterBold>
        <br />
        お客さまは、本アプリを無償で利用することができます。但し、お客さまは、本アプリの利用に伴い必要となるイーサリアムブロックチェーン上の取引手数料（ガス）を負担するものとします。
        <br />
        <br />
        <br />
        <CenterBold>第7条 本機能による貸出の利用方法</CenterBold>
        <br />
        <TextIndentOne>
          1.当社は、当社ウェブサイト上において、次の各号に掲げる事項を、本機能による個別の対象暗号資産の貸出条件として公表するものとします。
        </TextIndentOne>
        <br />
        <TextIndentTwo>①貸出の申込期間</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ②各お客さまに付与される貸借料の合計額（以下「貸借料総額」といいます。）
        </TextIndentTwo>
        <br />
        <TextIndentTwo>③貸出開始日</TextIndentTwo>
        <br />
        <TextIndentTwo>④その他当社が必要と合理的に考える貸出条件</TextIndentTwo>
        <br />
        <TextIndentOne>
          2.前項において公表された貸出条件による対象暗号資産の貸出を希望するお客さまは、前項に従い公表される申込期間内に、当該貸付を希望する対象暗号資産の数量を指定し、かつ当該数量に相当する対象暗号資産をお客さまウォレットから本アプリに送付する方法により、当該貸出の申込みを行うものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          3.貸出期間は、第1項第3号に定める貸出開始日から起算して168日間とします（当該168日目に該当する日を、以下「満期日」といい、当該貸出開始日から当該満期日までを、「貸出期間」といいます。）。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          4.お客さまが次条に従い貸出の継続を希望しない旨を申し入れた場合、お客さまが貸し出した対象暗号資産は、満期日以降において、本アプリからお客さまご自身がお客さまウォレットに送付する方法により、返還されるものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          5.貸借料は、貸出期間中、お客さまが対象暗号資産を貸し出すことにより、満期日において付与されるものとします。当該貸借料は、満期日以降において、対象暗号資産と同種の暗号資産を本アプリからお客さまご自身がお客さまウォレットに送付する方法により、付与されるものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          6.前項の貸借料は、第1項第2号に従い公表される貸借料総額、第1項に従い公表される貸出条件に基づく全お客さまの総貸出数量、及び個々のお客さまの貸出数量に基づき、[下記計算方法により]算出されるものとします。
        </TextIndentOne>
        <br />
        <Center>記</Center>
        <br />
        <Center>
          貸借料の計算式：
          <br />
          貸借料総額 × (個々のお客さまの貸出数 ÷ 全お客さまの総貸出数量)
        </Center>
        <br />
        <TextIndentOne>
          7.お客さまは、本アプリ内のお客さま専用画面において、自己の貸出数量、前2項に基づき付与される予定の貸借料、その他本機能の利用履歴等の情報を確認することができます。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          8.お客さまは、本規約のほか、当社が別途定める本アプリの利用条件、利用方法等に従い、本アプリを利用するものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第8条 貸出の継続</CenterBold>
        <br />
        貸出期間は、お客さまが満期日までに本アプリ所定の方法により貸出の継続を希望しない旨を申し入れない限り、当社が当社ウェブサイト上で新たに公表する条件でさらに168日間延長されるものとし、以後同様とします。ただし、当社都合により貸出の継続を受け付けないことを決定した場合は、この限りでありません。
        <br />
        <br />
        <br />
        <CenterBold>第9条 ハードフォーク等に関する取扱い</CenterBold>
        <br />
        第7条第2項に基づき本アプリに対象暗号資産を送付してから本規約に従い当該対象暗号資産が現に返還されるまでの間に、当該対象暗号資産について、ハードフォーク（対象暗号資産に係るブロックチェーンが分岐することにより、新しい別個の暗号資産が生じることをいう。）、エアドロップ（対象暗号資産の発行体又は発行体の関係者等が、対象暗号資産の保有者に対して同一種類又は別の種類の暗号資産を無償で付与する行為をいう。）その他これに類する事象（総称して、以下「ハードフォーク等」といいます。）により新たな暗号資産その他の権利及び財産的価値等が発生した場合であっても、お客さまは当社に対し、当該新たな暗号資産等の付与及びその取扱いを含め何らの請求をすることはできないものとします。
        <br />
        <br />
        <br />
        <CenterBold>第10条 お客さま都合による貸出の終了等</CenterBold>
        <br />
        <TextIndentOne>
          1.お客さまは、第7条第1項第1号の申込期間中、同条第2項に基づく申込みを撤回することができ、その場合、同項に基づき本アプリに送付した対象暗号資産の返還を受けることができます。この場合、第7条第5項及び第6項に基づく貸借料は付与されないものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          2.お客さまは、貸出期間中、いかなる事由によっても第7条に基づく貸出を終了することはできません。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第11条 禁止事項</CenterBold>
        <br />
        <TextIndentOne>
          1.お客さまは、次の各号に定める行為を行ってはならないものとします。お客さまの行為が当該禁止行為に該当するか否かは当社が合理的に判断するものとします。
        </TextIndentOne>
        <br />
        <TextIndentTwo>
          ①当社又は第三者の著作権、商標権等の知的財産権その他の権利を侵害する行為又は侵害するおそれのある行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>②本アプリにより利用できる情報を改ざんし、又は消去する行為</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ③当社が提供する本アプリ以外のツール等を使用した取引、又はその疑いのある行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>④本規約に違反して、第三者に本アプリを利用させる行為</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑤法令若しくは公序良俗に違反し、又は当社若しくは第三者に不利益を与える行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑥マネー・ローンダリングに関連する行為若しくはこれに類似する行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑦他者を差別し、若しくは誹謗中傷し、又はその名誉若しくは信用を毀損する行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>⑧詐欺等の犯罪に結びつく行為又は結びつくおそれのある行為</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑨わいせつ、児童ポルノ又は児童虐待にあたる画像、文書等を送信し、又は掲載する行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>⑩無限連鎖講を開設し、又はこれを勧誘する行為</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑪ウィルス等の有害なコンピュータープログラム等を送信し、又は掲載する行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑫無断で第三者に広告、宣伝若しくは勧誘の電子メールを送信する行為又は第三者が不快感を抱く、若しくはそのおそれのある電子メール（嫌がらせメール）を送信する行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑬本アプリの利用若しくは運営に支障を与える行為又は与えるおそれのある行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑭当社の承諾を得ることなく、本アプリを通じて取得した情報を本アプリ以外の目的で利用し、又は第三者に開示し、若しくは漏洩する行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑮自ら又は第三者を利用した、暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動をし、又は暴力を用いる行為、風説を流布し、偽計又は威力を用いて当社の信用を毀損し、又は当社の業務を妨害する行為、その他これらに準ずる行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>⑯本アプリの利用状況が不適当又は不審と認める行為</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑰本アプリについて逆アセンブル、逆コンパイル、リバースエンジニアリングを行う行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑱他人を錯誤に陥れるような手段を用いる詐欺的な行為又は意図的に他人の射幸心をあおるような言動を行う行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑲前各号の行為を助長する態様又は目的でインターネット上にリンクを作成する行為
        </TextIndentTwo>
        <br />
        <TextIndentTwo>⑳その他当社が不適切であると合理的に認める行為</TextIndentTwo>
        <br />
        <TextIndentOne>
          2.当社は、お客さまの行為が前項のいずれかに該当し、又は該当するおそれがあると当社が合理的に判断した場合には、事前の通知なく、当該違反行為の全部又は一部の停止、本アプリの全部若しくは一部の提供の停止又は終了等の措置を講じることができるものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          3.当社は、当社に故意又は過失が無い限り、前項に基づき当社が行った措置によりお客さまに生じた損害について責任を負わないものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第12条 利用契約の解約等</CenterBold>
        <br />
        <TextIndentOne>
          1.当社は、お客さまが次の各号のいずれかに該当した場合、何らの通知・催告等を要せず直ちに、本アプリの全部若しくは一部の提供を停止し、又は利用契約の解約等の措置を講じることができるものとします。
        </TextIndentOne>
        <br />
        <TextIndentTwo>①お客さまが反社会的勢力に該当する場合</TextIndentTwo>
        <br />
        <TextIndentTwo>②お客さまが反社会的行為を行った場合</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ③本規約に違反し、かつ、当社からの期間を定めた催告にもかかわらず、当該違反が当該期間内に治癒されない場合
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ④その他、本アプリのご利用の継続が適当でないと当社が合理的に判断した場合
        </TextIndentTwo>
        <br />
        <TextIndentOne>
          2.当社は、当社に故意又は過失が無い限り、前項に基づき当社が行った措置によりお客さまに生じた損害について責任を負わないものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第13条 本アプリの提供中止、終了等</CenterBold>
        <br />
        <TextIndentOne>
          1.当社は、次の各号に各号のいずれかに該当する場合、事前にお客さまに通知することなく、本アプリの全部又は一部の提供を中止することがあります。
        </TextIndentOne>
        <br />
        <TextIndentTwo>
          ①本アプリに係るコンピューター、電気通信設備その他の機器及びソフトウェア（以下「本アプリ用設備」といいます。）の点検又は保守作業を緊急に行う場合
        </TextIndentTwo>
        <br />
        <TextIndentTwo>②コンピューター、通信回線等が事故、故障等により停止した場合</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ③天災地変等の不可抗力、戦争、ストライキ、疫病、法令・規則等の変更、暗号資産に関する事情の急変などにより当社が本アプリを提供することができない場合
        </TextIndentTwo>
        <br />
        <TextIndentTwo>④裁判所の命令又は法令に基づく強制的な処分が行われた場合</TextIndentTwo>
        <br />
        <TextIndentTwo>⑤運用上又は技術上の理由でやむを得ない場合</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑥第三者による本アプリ用設備への不正アクセス等により、本アプリ用設備の安全性を確認する必要がある場合
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑦その他、当社が本アプリの提供を中止することが合理的であると判断した場合
        </TextIndentTwo>
        <br />
        <TextIndentOne>
          2.当社は、本アプリ用設備の点検又は保守作業を行うため、事前にお客さまに通知のうえ、本アプリの提供を中止することができるものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          3.当社は、社会情勢の変化、法令の改廃その他やむを得ない事情があると合理的に判断する場合、事前に告知を行ったうえで、本件アプリの全部若しくは一部を変更し、又はその提供を終了する場合があります。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          4.当社は、当社に故意又は過失がない限り、前各項による本アプリの提供中止、変更又は終了により、お客さまに発生した損害について責任を負わないものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第14条 個人情報の取り扱い</CenterBold>
        <br />
        当社は、本アプリの提供に際し、お客さまの個人情報を取得する場合には、当社が別途定めるプライバシーポリシーに従い当該個人情報を取り扱うものとします。
        <br />
        <br />
        <br />
        <CenterBold>第15条 権利の帰属</CenterBold>
        <br />
        <TextIndentOne>
          1.当社ウェブサイト及び本アプリに関する所有権及び知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、利用規約に基づく本アプリの利用許諾は、当社ウェブサイト又は本アプリに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。お客さまは、いかなる理由によっても当社又は当社にライセンスを許諾している者の知的財産権を侵害するおそれのある行為（逆アセンブル、逆コンパイル、リバースエンジニアリングを含みますが、これらに限定されません。）をしないものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          2.当社ウェブサイト又は本アプリにおいて、お客さまが投稿その他送信を行った文章、画像、動画その他のデータについては、法令の認める範囲内で、当社において、無償で自由に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第16条 保証の否認又は免責</CenterBold>
        <br />
        <TextIndentOne>
          1.本アプリは現状有姿で提供されるものであり、当社は本アプリについて、特定の目的への適合性、商業的有用性、完全性、継続性等を含め、保証を致しません。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          2.当社は、対象暗号資産の価値、機能、使用先及び用途につき何ら保証を行うものではなく、如何なる保証及び責任（契約不適合責任を含みます。）を負わないものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          3.当社は、次の各号に掲げる損害については、債務不履行、不法行為その他の法律上の請求原因の如何を問わず、責任を負わないものとします。但し、当該損害が当社の故意又は過失に起因して発生した場合はこの限りではありません。
        </TextIndentOne>
        <br />
        <TextIndentTwo>①天災地変、戦争、騒乱、暴動等の不可抗力に起因する損害</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ②お客さま設備の障害又は本アプリ用設備までのインターネット接続サービスの不具合等のお客さまの接続環境の障害に起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ③本アプリ用設備の応答時間等、インターネット接続サービスの性能値に起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ④当社が第三者から導入しているコンピューターウィルス対策ソフトについて当該第三者からウィルスパターン、ウィルス定義ファイル等を提供されていない種類のコンピューターウィルスの本アプリ用設備への侵入に起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑤善良な管理者の注意をもってしても防御することができない本サービス用設備への第三者による不正アクセス、アタック又は通信経路上での傍受に起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑥当社が定める手順、セキュリティ手段等をお客さまが遵守しないことに起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑦本アプリ用設備のうち第三者が製作したソフトウェア（OS、ミドルウェアを含みます。）に起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑧本アプリ用設備のうち第三者が製作するハードウェア及びデータベースに起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>⑨電気通信事業者の提供する電気通信役務の不具合に起因する損害</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑩刑事訴訟法第218条（令状による差押え・捜索・検証）、犯罪捜査のための通信傍受に関する法律その他の法令又は裁判所の命令に基づく強制的な処分に起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑪本アプリに関する法令の新設、改廃、解釈の変更等（その効果が過去に遡及する場合を含みます。）に起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>
          ⑫当社ウェブサイトとのリンクの有無を問わず、第三者のウェブサイトに起因する損害
        </TextIndentTwo>
        <br />
        <TextIndentTwo>⑬その他当社の責めに帰することができない事由に起因する損害</TextIndentTwo>
        <br />
        <TextIndentOne>
          4.当社は、お客さまが本アプリを利用することにより、お客さまと第三者との間で生じた紛争等について、当社に故意又は過失がない限り、責任を負わないものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第17条 損害賠償</CenterBold>
        <br />
        <TextIndentOne>
          1.お客さまは、本規約に違反することにより、又は本アプリの利用に関連してその故意又は過失により当社に損害を与えた場合、当社に対しその損害を賠償しなければなりません。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          2.前条にかかわらず、当社が本アプリの利用に関連してお客さまが被った損害について責任を負う場合であっても、その損害は現実に発生した直接かつ通常の損害に限るものとし、お客さまの逸失利益（得べかりし利益）について当社はその責任を負わないものとします。但し、当該損害が当社の故意又は重大な過失に起因して発生した場合はこの限りではありません。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第18条　有効期間及び存続条項</CenterBold>
        <br />
        <TextIndentOne>
          1.利用契約は、第5条に基づき利用契約が成立した日から本規約に従い利用契約が終了する日又は当社が本アプリの提供を終了する日まで、当社とお客さまとの間で有効に存続するものとします。
        </TextIndentOne>
        <br />
        <TextIndentOne>
          2.前条にかかわらず、第6条、第7条第4項から第6項、第9条、第12条第2項、第13条第4項、第14条第2項、第15条、第16条、第17条、第20条から第22条は、利用契約の有効期間終了後も引き続き効力を有するものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第19条 本規約の変更</CenterBold>
        <br />
        <TextIndentOne>
          1.本規約は、当社の合理的な判断により、次の各号に掲げる場合に変更されることがあります。
        </TextIndentOne>
        <br />
        <TextIndentTwo>①本規約の変更が、お客さまの一般の利益に適合する場合</TextIndentTwo>
        <br />
        <TextIndentTwo>
          ②本規約の変更が、本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものである場合
        </TextIndentTwo>
        <br />
        <TextIndentOne>
          2.当社は、前項に基づき本規約を変更する場合には、その効力発生時期を定め、その効力発生時期までに、予め、本規約を変更する旨、当該変更後の内容及び当該変更の効力発生時期を当社ウェブサイト上で公表するものとします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第20条 譲渡禁止</CenterBold>
        <br />
        お客さまは、利用契約上の地位又は本規約に基づく権利義務の全部若しくは一部を、第三者に対し譲渡、承継、又は第三者のために担保設定をする等一切の処分をすることはできません
        <br />
        <br />
        <br />
        <CenterBold>第21条 準拠法及び合意管轄</CenterBold>
        <br />
        <TextIndentOne>1.本規約の準拠法は、日本法とします。</TextIndentOne>
        <br />
        <TextIndentOne>
          2.お客さまと当社との間で生じた本規約及び本アプリに関する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </TextIndentOne>
        <br />
        <br />
        <CenterBold>第22条 協議</CenterBold>
        <br />
        当社及びお客さまは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上、速やかに解決を図るものとします。
        <br />
      </BoxTerm>
      <Spacer size="lg" />
      <Center>
        上記、規約に同意して申し込む
        <Spacer size="sm" />
        <CheckBox onChange={(e: any) => setConfirmed(e.target.checked)} />
      </Center>
      <Spacer size="lg" />
      <Button
        isDisabled={!confirmed}
        onClick={() => {
          history.push('/lending/form');
        }}
      >
        申し込む
      </Button>
    </Layout>
  );
});
