import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const lsKey = '7d540f58d68e1c33a6e2a32aaaa2f4992fa97546b2432f504330593ec6b7d64f'; // sha3-256('app-setting')

type SettingKey = 'allowPushNotifications' | 'approveInfinity';

export type SettingState = Map<SettingKey, string>;
const initialState: SettingState = new Map<SettingKey, string>([
  ['allowPushNotifications', 'false'],
  ['approveInfinity', 'false'],
]);

export const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    init: () => {
      const item = localStorage.getItem(lsKey);
      if (!item) {
        localStorage.setItem(lsKey, JSON.stringify(Array.from(initialState.entries())));

        return initialState;
      }
      const newState = new Map(JSON.parse(item) as any) as SettingState;

      return newState;
    },
    update: (
      state: any,
      action: PayloadAction<{
        key: SettingKey;
        value: string;
      }>,
    ) => {
      state.set(action.payload.key, action.payload.value);
      localStorage.setItem(lsKey, JSON.stringify(Array.from(state.entries())));

      return state;
    },
  },
});
