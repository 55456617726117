import axios from 'axios';
import { BigNumber as BN } from 'bignumber.js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { styled } from 'twin.macro';
import { Box, BoxBg } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { Center } from 'components/atoms/Center';
import { FlexBox } from 'components/atoms/FlexBox';
import { Footer } from 'components/atoms/Footer';
import { HeaderTitle, SectionSubTitle } from 'components/atoms/Heading';
import { Spacer } from 'components/atoms/Spacer';
import { Value } from 'components/atoms/Value';
import { Layout } from 'components/layouts';
import { CancelLendingConfirmModal, LendingConfirmModal } from 'components/modal';
import { setLoading, setNoUpdateButtonFlg, setCloseIndicate } from 'features/handover';
import { RootState } from 'features/reducer';
import {
  StakingAccountInfoList,
  StakingConfigList,
  StakingTokenInfoList,
} from 'fisco/stakingAggregator';
import { useModal } from 'hooks';
import { useFetch } from 'hooks/staking/useFetchStaking';
import { useStaking } from 'hooks/staking/useStaking';
import { getCurrentTerm, getMaturityDate } from 'utils/date';

interface MaturityHistory {
  maturityDate: string;
  amount: string;
  // reward: BN;
}
export const ConfirmationContractDetail: React.FC = React.memo(() => {
  const history = useHistory();
  const staking = useStaking();
  const fetch = useFetch();
  const dispatch = useDispatch();
  const [openLendingConfirmModal, _] = useModal(
    <LendingConfirmModal
      onClick={() => {
        history.push('/lending/introduction');
      }}
    />,
  );
  const [openModal, __] = useModal(<CancelLendingConfirmModal />);

  const accountInfo = fetch.accountInfo;
  const tokenConfig = fetch.config;
  const tokenInfo = fetch.tokenInfo;

  // 満期日で止めるボタンdisabled判定
  let isNotExistContract = true;
  (accountInfo?.withdrawableStakingAmountList
    ? accountInfo?.withdrawableStakingAmountList
    : []
  ).map((amountList) => {
    if (amountList.toString() !== '0') {
      isNotExistContract = false;
    }
  });
  // 利息を受け取る（元本及び利息を受け取る）ボタン判定
  let rewardButtonType = '';
  let receivableReward = false;
  if (fetch.receivableReward) {
    receivableReward = fetch.receivableReward.gt(new BN(0));
  }

  const [datetime, setDatetime] = useState<string>('');
  useEffect(() => {
    const fetchDateTime = async () => {
      const result = await axios.head('https://fiscocoin.com/lending');
      // const result = await axios.head('https://test-lending.f5.si/lending');
      // const result = await axios.head('http://localhost:3000/lending');
      setDatetime(result.headers.date);
    };
    fetchDateTime();
  }, []);

  let isContractChecked = false;

  interface MaturityDate {
    index: number;
    value: Date;
  }
  const maturityDateList: MaturityDate[] = [];
  if (tokenConfig?.startTimestamp) {
    (accountInfo?.withdrawableStakingAmountList
      ? accountInfo?.withdrawableStakingAmountList
      : []
    ).forEach((info, index) => {
      if (info.toString() !== '0') {
        const date: MaturityDate = {
          index: index,
          value: getMaturityDate(
            staking.cycle,
            tokenConfig?.startTimestamp[0],
            index,
            new Date(datetime),
          ),
        };
        maturityDateList.push(date);
      }
      if (index == 5) {
        isContractChecked = true;
      }
    });
  }
  // ソート
  const sortedList = maturityDateList.sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    } else {
      return 1;
    }
  });

  // 解約済み満期一覧作成
  const maturityHistory: MaturityHistory[] = [];
  let isMaturityHistoryChecked = false;
  // 旧計算式
  // if (
  //   accountInfo?.userTermList &&
  //   accountInfo?.nextAddedStakeAmountList &&
  //   tokenInfo?.latestTermList &&
  //   tokenConfig?.startTimestamp
  // ) {
  //   const nextAddedStakeAmountList = accountInfo?.nextAddedStakeAmountList;
  //   nextAddedStakeAmountList.forEach((item, index) => {
  //     if (item.lt(0)) {
  //       const startTimestamp = tokenConfig.startTimestamp[0];
  //       let maturityDate = new Date();
  //       if (startTimestamp && staking.cycle > 0) {
  //         maturityDate = getMaturityDate(staking.cycle, startTimestamp, index, new Date(datetime));
  //       }
  //       if (maturityDate.getTime() - new Date(datetime).getTime() > staking.cycle) {
  //         if (startTimestamp && staking.cycle > 0) {
  //           maturityDate = getMaturityDateForCancellation(
  //             staking.cycle,
  //             startTimestamp,
  //             index,
  //             new Date(datetime),
  //           );
  //         }
  //       }
  //       let maturityDateLabel = '';
  //       if (maturityDate.getFullYear() !== NaN) {
  //         maturityDateLabel =
  //           maturityDate.getFullYear().toString() +
  //           '/' +
  //           ('00' + (maturityDate.getMonth() + 1).toString()).slice(-2) +
  //           '/' +
  //           ('00' + maturityDate.getDate().toString()).slice(-2) +
  //           ' ' +
  //           ('00' + maturityDate.getHours().toString()).slice(-2) +
  //           ':' +
  //           ('00' + maturityDate.getMinutes().toString()).slice(-2);
  //       }
  //       const history: MaturityHistory = {
  //         maturityDate: maturityDateLabel,
  //         amount: Math.abs(Number(item)).toString(),
  //       };
  //       maturityHistory.push(history);
  //     }
  //   });
  // }
  const depositAmountList = fetch.accountInfo?.withdrawableStakingAmountList
    ? fetch.accountInfo?.withdrawableStakingAmountList
    : [];
  depositAmountList.forEach((item, index) => {
    if (item.lt(0)) {
      // let reward = new BN(0);
      let maturityDateLabel = '';
      if (
        fetch.accountInfo?.nextTermUserRewardsList &&
        fetch.accountInfo?.currentTermUserRewardsList &&
        fetch.accountInfo?.userTermList &&
        fetch.tokenInfo &&
        fetch.tokenInfo.latestTermList &&
        fetch.config &&
        fetch.config.startTimestamp
      ) {
        // const nextTermUserReward = fetch.accountInfo?.nextTermUserRewardsList[index];
        // const currentTermUserReward = fetch.accountInfo?.currentTermUserRewardsList[index];
        const userTerm = fetch.accountInfo?.userTermList[index];
        const latestTerm = fetch.tokenInfo.latestTermList[index];

        // 今どの申込タームにいるか
        const currentTerm = getCurrentTerm(staking.cycle, fetch.config?.startTimestamp[0]);

        // if (nextTermUserReward.eq(0)) {
        //   reward = currentTermUserReward;
        // } else {
        //   if (currentTerm == index && userTerm != latestTerm) {
        //     reward = currentTermUserReward;
        //   } else {
        //     reward = nextTermUserReward;
        //   }
        // }

        // 満期計算
        const startTimestamp = fetch.config?.startTimestamp[0];
        let maturityDate = new Date();
        if (startTimestamp && staking.cycle > 0) {
          maturityDate = getMaturityDate(staking.cycle, startTimestamp, index, new Date(datetime));
        }
        //  && userTerm != latestTerm 202104102053

        // if (currentTerm == index) { 202104111447
        if (currentTerm == index || userTerm != latestTerm) {
          maturityDate = new Date(maturityDate.getTime() - staking.cycle);
        }
        if (maturityDate.getFullYear() !== NaN) {
          maturityDateLabel =
            maturityDate.getFullYear().toString() +
            '/' +
            ('00' + (maturityDate.getMonth() + 1).toString()).slice(-2) +
            '/' +
            ('00' + maturityDate.getDate().toString()).slice(-2);
        }

        const history: MaturityHistory = {
          maturityDate: maturityDateLabel,
          amount: Math.abs(Number(item)).toString(),
          // reward: reward,
        };
        maturityHistory.push(history);
      }
    }
    if (index == 5) {
      isMaturityHistoryChecked = true;
    }
  });

  // 利息を受け取るボタン状態管理
  const [interestState, setInterestState] = useState<boolean>(false);
  // 自動繰越停止ボタン状態管理
  const noUpdateButtonFlg = useSelector((state: RootState) => state.handover.noUpdateButtonFlg);

  let isWithdrawableStakingAmount = false;

  if (maturityHistory.length > 0) {
    if (
      accountInfo?.userTermList &&
      tokenInfo?.latestTermList &&
      accountInfo?.nextAddedStakeAmountList
    ) {
      accountInfo.userTermList.forEach((userTerm, index) => {
        if (
          !accountInfo.nextAddedStakeAmountList[index].eq(0) &&
          userTerm != tokenInfo.latestTermList[index]
        ) {
          isWithdrawableStakingAmount = true;
        }
      });
    }
    if (receivableReward) {
      if (isWithdrawableStakingAmount) {
        rewardButtonType = 'all';
      } else {
        rewardButtonType = 'onlyReward';
      }
    } else {
      rewardButtonType = 'disabled';
    }
  } else {
    // ステーキング額が存在するか判定
    (accountInfo?.withdrawableStakingAmountList
      ? accountInfo?.withdrawableStakingAmountList
      : []
    ).map((v) => {
      if (v.toString() !== '0') {
        isWithdrawableStakingAmount = true;
      }
    });
    if (receivableReward) {
      if (isWithdrawableStakingAmount) {
        rewardButtonType = 'onlyReward';
      } else {
        rewardButtonType = 'all';
      }
    } else {
      rewardButtonType = 'disabled';
    }
  }

  // setTimeout(() => {
  //   if (fetch.receivableReward?.eq(0)) {
  //     dispatch(setLoading(false));
  //     setInterestState(true);
  //   }

  //   if (fetch.accountInfo && fetch.accountInfo?.withdrawableStakingAmountList) {
  //     let isWithDrawable = false;
  //     fetch.accountInfo?.withdrawableStakingAmountList.forEach((item) => {
  //       if (item.gt(0)) {
  //         isWithDrawable = true;
  //       }
  //     });
  //     if (!isWithDrawable) {
  //       dispatch(setLoading(false));
  //       dispatch(setNoUpdateButtonFlg(true));
  //     }
  //     console.log(isWithDrawable);
  //   }
  // }, 3000);

  useEffect(() => {
    if (fetch.receivableReward?.eq(0)) {
      dispatch(setLoading(false));
      setInterestState(true);
    }
  }, [dispatch, fetch.receivableReward]);

  useEffect(() => {
    if (fetch.accountInfo && fetch.accountInfo?.withdrawableStakingAmountList) {
      let isWithDrawable = false;
      fetch.accountInfo?.withdrawableStakingAmountList.forEach((item) => {
        if (item.gt(0)) {
          isWithDrawable = true;
        }
      });
      if (!isWithDrawable) {
        dispatch(setLoading(false));
        dispatch(setNoUpdateButtonFlg(true));
        dispatch(setCloseIndicate(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, fetch.accountInfo]);

  return (
    <>
      <Layout id="contract-details">
        <Spacer size="md" />
        {!isContractChecked || !isMaturityHistoryChecked ? '' : <HeaderTitle>貸出状況</HeaderTitle>}
        <Spacer size="md" />
        <Spacer size="lg" />
        {/* <SectionSubTitle>受取可能元本・利息枚数</SectionSubTitle>
          <FlexBox>
            <Value value={fetch.receivableReward} unit="枚" />
          </FlexBox> */}
        {(maturityHistory ? maturityHistory : []).length > 0 ? (
          // 解約情報が存在する場合
          rewardButtonType === 'all' ? (
            <>
              {sortedList.length == 0 ? (
                <>
                  <HeaderTitle>ご利用ありがとうございました。</HeaderTitle>
                  <Spacer size="sm" />
                </>
              ) : (
                ''
              )}
              <HeaderTitle>
                返還可能な貸借数、
                <br />
                お受取り可能な貸借料がございます。
              </HeaderTitle>
              <Spacer size="md" />
              <StyledDiv>
                お受取り可能な貸借数、貸借料の合計数は{' '}
                <StyledSpan>
                  {fetch.receivableReward
                    ? (Math.round(Number(fetch.receivableReward.times(10))) / 10).toString()
                    : '0'}
                </StyledSpan>
                枚 となります。
              </StyledDiv>
              <StyledDiv>「貸借数と貸借料を受取る」ボタンよりお受取りいただけます。</StyledDiv>
              <Spacer size="lg" />
            </>
          ) : rewardButtonType === 'onlyReward' ? (
            <>
              {sortedList.length == 0 ? (
                <>
                  <HeaderTitle>ご利用ありがとうございました。</HeaderTitle>
                  <Spacer size="sm" />
                </>
              ) : (
                ''
              )}
              <HeaderTitle>お受取り可能な貸借料がございます。</HeaderTitle>
              <Spacer size="md" />
              <StyledDiv>
                現在お受取り可能な貸借料は{' '}
                <StyledSpan>
                  {fetch.receivableReward
                    ? (Math.round(Number(fetch.receivableReward.times(10))) / 10).toString()
                    : '0'}
                </StyledSpan>
                枚 となります。
              </StyledDiv>
              <StyledDiv>「貸借料を受取る」ボタンよりお受取りいただけます。</StyledDiv>
              <Spacer size="lg" />
            </>
          ) : (
            <>
              {sortedList.length == 0 ? (
                <>
                  <HeaderTitle>ご利用ありがとうございました。</HeaderTitle>
                  <Spacer size="sm" />
                </>
              ) : (
                ''
              )}
              <StyledDiv>満期日は以下の通りです。</StyledDiv>
              <StyledDiv>
                満期日以降に「貸借数と貸借料を受取る」ボタンよりお引き出し下さい。
              </StyledDiv>
              <Spacer size="lg" />
            </>
          )
        ) : sortedList.length > 0 ? (
          rewardButtonType === 'onlyReward' ? (
            <>
              <HeaderTitle>お受取り可能な貸借料がございます。</HeaderTitle>
              <Spacer size="md" />
              <StyledDiv>
                現在お受取り可能な貸借料は{' '}
                <StyledSpan>
                  {fetch.receivableReward
                    ? (Math.round(Number(fetch.receivableReward.times(10))) / 10).toString()
                    : '0'}
                </StyledSpan>
                枚 となります。
              </StyledDiv>
              <StyledDiv>「貸借料を受取る」ボタンよりお受取りいただけます。</StyledDiv>
              <Spacer size="lg" />
            </>
          ) : (
            <>
              <HeaderTitle>現在貸借料はまだ発生しておりません。</HeaderTitle>
              <Spacer size="md" />
              <StyledDiv>
                貸借料のお受取りの際は満期日以降に
                <br />
                「貸借料を受取る」ボタンよりお受取りください。
              </StyledDiv>
              <StyledDiv>
                自動更新による貸出の継続を中止する際は
                <br />
                「自動更新を停止」ボタンよりお申込ください。
              </StyledDiv>
              <Spacer size="lg" />
            </>
          )
        ) : !isContractChecked || !isMaturityHistoryChecked ? (
          ''
        ) : (
          <>
            <HeaderTitle>ご利用ありがとうございました。</HeaderTitle>
            <Spacer size="md" />
            <HeaderTitle>またのご利用をお待ちしております。</HeaderTitle>
          </>
        )}
        {sortedList.length > 0 || (maturityHistory ? maturityHistory : []).length > 0 ? (
          <>
            {sortedList.length > 0 && (maturityHistory ? maturityHistory : []).length > 0 ? (
              <HeaderTitle>自動更新停止済み貸出状況一覧</HeaderTitle>
            ) : (
              <HeaderTitle>貸出状況一覧</HeaderTitle>
            )}
            <Spacer size="md" />
            {(maturityHistory ? maturityHistory : []).map((item, index) => {
              return (
                <MaturityHistoryBox
                  maturityHistory={item}
                  index={index}
                  key={'maturity' + index.toString()}
                />
              );
            })}
            {(maturityHistory ? maturityHistory : []).length > 0 ? <Spacer size="md" /> : ''}
            {sortedList.length > 0 && (maturityHistory ? maturityHistory : []).length > 0 ? (
              <>
                <HeaderTitle>貸出状況一覧</HeaderTitle>
                <Spacer size="md" />
              </>
            ) : (
              ''
            )}
            {sortedList.map((item) => {
              return (
                <ContractBox
                  key={item.index}
                  accountInfo={accountInfo}
                  tokenConfig={tokenConfig}
                  tokenInfo={tokenInfo}
                  index={item.index}
                />
              );
            })}
            <Spacer size="lg" />
            <Button
              isDisabled={rewardButtonType === 'disabled' || interestState}
              onClick={() => {
                staking
                  .receiveReward()
                  .then(() => {
                    setInterestState(true);
                  })
                  .finally(() => {
                    dispatch(setLoading(false));
                  });
                dispatch(setLoading(true));
              }}
            >
              {maturityHistory.length > 0 && rewardButtonType === 'disabled'
                ? '貸借数と貸借料を受取る'
                : rewardButtonType === 'all'
                ? '貸借数と貸借料を受取る'
                : '貸借料を受取る'}
            </Button>

            <Spacer size="lg" />
            <Button
              isDisplay={(maturityHistory ? maturityHistory : []).length == 0}
              isDisabled={isNotExistContract || noUpdateButtonFlg}
              onClick={() => openModal()}
            >
              自動更新を停止
            </Button>
          </>
        ) : (
          ''
        )}
        <Spacer size="lg" />
      </Layout>
      <Footer onClick={() => openLendingConfirmModal()} />
    </>
  );
});

type Props = {
  accountInfo: StakingAccountInfoList | undefined;
  tokenInfo: StakingTokenInfoList | undefined;
  tokenConfig: StakingConfigList | undefined;
  index: number;
};

const ContractBox: React.FC<Props> = React.memo((props) => {
  const staking = useStaking();
  const fetch = useFetch();

  const [datetime, setDatetime] = useState<string>('');
  useEffect(() => {
    const fetchDateTime = async () => {
      const result = await axios.head('https://fiscocoin.com/lending');
      // const result = await axios.head('https://test-lending.f5.si/lending');
      // const result = await axios.head('http://localhost:3000/lending');
      setDatetime(result.headers.date);
    };
    fetchDateTime();
  }, []);

  let depositAmount = props.accountInfo?.nextAddedStakeAmountList[props.index]
    ? props.accountInfo?.nextAddedStakeAmountList[props.index]
    : new BN(0);
  let reward = new BN(0);
  let maturityDateLabel = '';
  // 旧計算式
  // if (
  //   nextTermReward !== undefined &&
  //   nextTermStaking !== undefined &&
  //   depositAmount !== undefined &&
  //   currentTermReward !== undefined &&
  //   currentTermStaking !== undefined
  // ) {
  //   if (nextTermReward.toString() == '0') {
  //     if (currentTermStaking.eq(0)) {
  //       reward = 0;
  //       rate = 0;
  //     } else {
  //       reward =
  //         Math.round(
  //           Number(depositAmount.times(currentTermReward).div(currentTermStaking).times(10)),
  //         ) / 10;
  //       rate = Math.round(Number(new BN(reward).dividedBy(depositAmount).times(1000))) / 10;
  //     }
  //   } else {
  //     if (nextTermStaking.eq(0)) {
  //       reward = 0;
  //       rate = 0;
  //     } else {
  //       reward =
  //         Math.round(Number(depositAmount.times(nextTermReward).div(nextTermStaking).times(10))) /
  //         10;
  //       rate =
  //         Math.round(Number(new BN(reward).dividedBy(depositAmount).times(100).times(10))) / 10;
  //     }
  //   }
  // }
  if (depositAmount.gt(0)) {
    if (
      fetch.accountInfo?.nextTermUserRewardsList &&
      fetch.accountInfo?.currentTermUserRewardsList &&
      fetch.accountInfo?.userTermList &&
      fetch.tokenInfo &&
      fetch.tokenInfo.latestTermList &&
      fetch.config &&
      fetch.config.startTimestamp
    ) {
      const nextTermUserReward = fetch.accountInfo?.nextTermUserRewardsList[props.index];
      const currentTermUserReward = fetch.accountInfo?.currentTermUserRewardsList[props.index];
      const userTerm = fetch.accountInfo?.userTermList[props.index];
      const latestTerm = fetch.tokenInfo.latestTermList[props.index];

      // 今どの申込タームにいるか
      const currentTerm = getCurrentTerm(staking.cycle, fetch.config?.startTimestamp[0]);

      if (nextTermUserReward.eq(0)) {
        if (userTerm == latestTerm) {
          reward = currentTermUserReward;
        } else if (depositAmount.gt(0)) {
          reward = reward.plus(currentTermUserReward);
        }
      } else {
        if (currentTerm == props.index && userTerm != latestTerm) {
          reward = currentTermUserReward;
        } else {
          reward = nextTermUserReward;
        }
      }

      // 満期計算
      const startTimestamp = props.tokenConfig?.startTimestamp[0];
      let maturityDate = new Date();
      if (startTimestamp && staking.cycle > 0) {
        maturityDate = getMaturityDate(
          staking.cycle,
          startTimestamp,
          props.index,
          new Date(datetime),
        );
      }
      if (currentTerm == props.index && userTerm != latestTerm) {
        maturityDate = new Date(maturityDate.getTime() - staking.cycle);
      }
      if (maturityDate.getFullYear() !== NaN) {
        maturityDateLabel =
          maturityDate.getFullYear().toString() +
          '/' +
          ('00' + (maturityDate.getMonth() + 1).toString()).slice(-2) +
          '/' +
          ('00' + maturityDate.getDate().toString()).slice(-2);
      }
    }
  } else if (
    fetch.accountInfo?.withdrawableStakingAmountList &&
    fetch.accountInfo?.withdrawableStakingAmountList[props.index].gt(0) &&
    fetch.accountInfo?.nextTermUserRewardsList &&
    fetch.accountInfo?.currentTermUserRewardsList &&
    fetch.accountInfo?.userTermList &&
    fetch.tokenInfo &&
    fetch.tokenInfo.latestTermList &&
    fetch.config &&
    fetch.config.startTimestamp
  ) {
    depositAmount = fetch.accountInfo?.withdrawableStakingAmountList[props.index];
    const nextTermUserReward = fetch.accountInfo?.nextTermUserRewardsList[props.index];
    const currentTermUserReward = fetch.accountInfo?.currentTermUserRewardsList[props.index];
    const userTerm = fetch.accountInfo?.userTermList[props.index];
    const latestTerm = fetch.tokenInfo.latestTermList[props.index];

    // 今どの申込タームにいるか
    const currentTerm = getCurrentTerm(staking.cycle, fetch.config?.startTimestamp[0]);

    if (nextTermUserReward.eq(0)) {
      reward = reward.plus(currentTermUserReward);
    } else {
      if (currentTerm == props.index && userTerm != latestTerm) {
        reward = reward.plus(currentTermUserReward);
      } else if (userTerm == latestTerm) {
        if (currentTermUserReward.eq(0)) {
          reward = reward.plus(nextTermUserReward);
        } else {
          reward = reward.plus(currentTermUserReward);
        }
      } else {
        reward = reward.plus(nextTermUserReward);
      }
    }

    // 満期計算
    const startTimestamp = props.tokenConfig?.startTimestamp[0];
    let maturityDate = new Date();
    if (startTimestamp && staking.cycle > 0) {
      maturityDate = getMaturityDate(
        staking.cycle,
        startTimestamp,
        props.index,
        new Date(datetime),
      );
    }
    if (currentTerm == props.index) {
      maturityDate = new Date(maturityDate.getTime() - staking.cycle);
    }
    if (maturityDate.getFullYear() !== NaN) {
      maturityDateLabel =
        maturityDate.getFullYear().toString() +
        '/' +
        ('00' + (maturityDate.getMonth() + 1).toString()).slice(-2) +
        '/' +
        ('00' + maturityDate.getDate().toString()).slice(-2);
    }
  }

  // 利息率
  const rate = Math.round(Number(reward.dividedBy(depositAmount).times(1000))) / 10;

  return (
    <>
      <BoxBg>
        <SectionSubTitle>満期日</SectionSubTitle>
        <FlexBox>
          <Value value={maturityDateLabel} position="center" />
        </FlexBox>
        <Spacer size="xs" />
        <Center>168日間コース</Center>
      </BoxBg>
      <Box>
        <SectionSubTitle>貸借数</SectionSubTitle>
        <FlexBox>
          <Value value={depositAmount.toString()} unit="枚" />
        </FlexBox>
        <SectionSubTitle>貸借料（予定）</SectionSubTitle>
        <FlexBox>
          <Value value={(Math.round(Number(reward.times(10))) / 10).toString()} unit="枚" />
        </FlexBox>
        <SectionSubTitle>貸借料率（予定）</SectionSubTitle>
        <FlexBox>
          <Value value={rate.toString()} unit="%" />
        </FlexBox>
      </Box>
      <Spacer size="lg" />
    </>
  );
});

type HistoryProps = {
  maturityHistory: MaturityHistory;
  index: number;
};

const MaturityHistoryBox: React.FC<HistoryProps> = React.memo((props) => {
  // const rate =
  //   Math.round(
  //     Number(props.maturityHistory.reward.dividedBy(props.maturityHistory.amount).times(1000)),
  //   ) / 10;

  return (
    <>
      <Box>
        <FlexBox>
          <SectionSubTitle>{'貸出' + (props.index + 1).toString() + ' 満期日'}</SectionSubTitle>
          <Value value={props.maturityHistory.maturityDate} />
        </FlexBox>
        <Spacer size="xs" />
        <FlexBox>
          <SectionSubTitle>{'貸出' + (props.index + 1).toString() + ' 貸借数'}</SectionSubTitle>
          <Value value={props.maturityHistory.amount} unit={'枚'} />
        </FlexBox>
      </Box>
      <Spacer size="lg" />
      {/* </BoxBg>
      <Box>
        <SectionSubTitle>貸出数</SectionSubTitle>
        <FlexBox>
          <Value value={props.maturityHistory.amount} unit="枚" />
        </FlexBox>
        <SectionSubTitle>利息数（予定）</SectionSubTitle>
        <FlexBox>
          <Value
            value={(Math.round(Number(props.maturityHistory.reward.times(10))) / 10).toString()}
            unit="枚"
          />
        </FlexBox>
        <SectionSubTitle>利息率（予定）</SectionSubTitle>
        <FlexBox>
          <Value value={rate.toString()} unit="%" />
        </FlexBox>
      </Box>
      <Spacer size="lg" /> */}
    </>
  );
});

const StyledDiv = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-top: 16px;
`;

const StyledSpan = styled.span`
  font-weight: 600;
  color: red;
`;
