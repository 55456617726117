import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { modalSlice } from 'features/modal';

export const useModal = (modal: React.ReactNode, key?: string): [() => void, any] => {
  const dispatch = useDispatch();
  const { onDismiss, onPresent } = modalSlice.actions;

  const openModal = useCallback(() => {
    dispatch(
      onPresent({
        modalContent: modal,
        key: key,
      }),
    );
  }, [key, modal, onPresent]); // eslint-disable-line

  return [openModal, onDismiss];
};
