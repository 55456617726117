import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalState = {
  content?: React.ReactNode;
  isOpen?: boolean;
  key: string;
};
const initialState: ModalState = { content: null, isOpen: false, key: '' };

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    onPresent: (
      state: any,
      action: PayloadAction<{
        modalContent: React.ReactNode;
        key?: string;
      }>,
    ) => {
      return {
        ...state,
        content: action.payload.modalContent,
        isOpen: true,
        key: action.payload.key,
      };
    },
    onDismiss: (state) => {
      return { ...state, content: null, isOpen: false };
    },
    reset: () => initialState,
  },
});
