export const white = '#FFFFFF';
export const black = '#000000';

export const lightBlue = '#e3f2fd';
export const darkBlue = '#0069c0';
export const red = '#f44336';

export const backdrop = '#7b7b7baa';
export const sectionBG = '#fffde7';

export const grey = {
  100: '#212121',
  200: '#424242',
  300: '#616161',
  400: '#9e9e9e',
  500: '#bbbbbb',
  600: '#bdbdbd',
  700: '#e0e0e0',
  800: '#f5f5f5',
};
