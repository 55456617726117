import React from 'react';
import { styled } from 'twin.macro';

export const BoxContent: React.FC = React.memo((props) => {
  const { children } = props;

  return <StyledDiv>{children}</StyledDiv>;
});

const StyledDiv = styled.div`
  //area-border
  border: 1px solid ${(props) => props.theme.color.grey[700]};
  padding: 16px;
`;
