import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionReceipt } from 'web3-core';

export interface Transaction {
  description: string;
  hash: string;
  receipt?: TransactionReceipt;
}

export type TxResponse = {
  data: Transaction;
  isSuccess: boolean;
  error?: Error;
};

export interface TransactionsMap {
  [key: string]: TxResponse;
}

export type TransactionState = {
  initialized: boolean;
  transactions: TransactionsMap;
};
const initialState: TransactionState = {
  initialized: false,
  transactions: {},
};

export const txSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    addTransaction: (
      state,
      action: PayloadAction<{
        txResponse: TxResponse;
      }>,
    ) => ({
      ...state,
      transactions: {
        ...state.transactions,
        [action.payload.txResponse.data.hash]: action.payload.txResponse,
      },
    }),
    receiveTxReceipt: (
      state,
      action: PayloadAction<{
        txHash: string;
        receipt: TransactionReceipt;
      }>,
    ) => ({
      ...state,
      transactions: {
        ...state.transactions,
        [action.payload.txHash]: {
          ...state.transactions[action.payload.txHash],
          receipt: action.payload.receipt,
        },
      },
    }),
    setTransactions: (
      state,
      action: PayloadAction<{
        transactions: TransactionsMap;
      }>,
    ) => ({
      ...state,
      transactions: action.payload.transactions,
      initialized: true,
    }),
    reset: () => initialState,
  },
});
