import { useSelector } from 'react-redux';
import useSWR from 'swr';
import { useWallet } from 'use-wallet';
import { RootState } from 'features/reducer';
import Fisco from 'fisco/fisco';
import { defaultRefreshInterval, longRefreshInterval, zeroAddress } from 'resource';

export const useFetch = () => {
  const fisco = useSelector((state: RootState) => state.sdk.fisco);
  const account = useWallet().account || zeroAddress;

  // tokenInfo
  const tokenInfoFetcher = (_: string, fisco: Fisco | null) =>
    fisco?.stakingAggregator.getTokenInfo();
  const { data: tokenInfo } = useSWR(['/stakingAggregator/getTokenInfo', fisco], tokenInfoFetcher, {
    refreshInterval: defaultRefreshInterval,
  });

  // accountInfo
  const accountInfoFetcher = (_: string, fisco: Fisco | null, a: string) =>
    fisco?.stakingAggregator.getAccountInfo(a);
  const { data: accountInfo } = useSWR(
    ['/stakingAggregator/getAccountInfo', fisco, account],
    accountInfoFetcher,
    {
      refreshInterval: defaultRefreshInterval,
    },
  );

  // config
  const configsFetcher = (_: string, fisco: Fisco | null) => fisco?.stakingAggregator.getConfigs();
  const { data: config } = useSWR(['/stakingAggregator/getConfigs', fisco], configsFetcher, {
    refreshInterval: longRefreshInterval,
  });

  // receivableReward
  const receivableRewardFetcher = (_: string, fisco: Fisco | null, a: string) =>
    fisco?.stakingAggregator.getReceiveReward(a);
  const { data: receivableReward } = useSWR(
    ['/stakingAggregator/getReceiveReward', fisco, account],
    receivableRewardFetcher,
    {
      refreshInterval: defaultRefreshInterval,
    },
  );

  // contracts
  const contractsFetcher = (_: string, fisco: Fisco | null) =>
    fisco?.stakingAggregator.getStakingContracts();
  const { data: contracts } = useSWR(
    ['/stakingAggregator/getStakingContracts', fisco],
    contractsFetcher,
    {
      refreshInterval: longRefreshInterval,
    },
  );

  return {
    tokenInfo: tokenInfo,
    accountInfo: accountInfo,
    config: config,
    receivableReward: receivableReward,
    contracts: contracts,
  };
};
