import React from 'react';
import { styled } from 'twin.macro';
import { Icon } from '../Icon';

type Props = React.HTMLAttributes<HTMLElement> & {
  src: string;
  isLoading?: boolean;
};

export const Loading: React.FC<Props> = React.memo((props) => {
  return (
    <>
      <StyledDiv isLoading={props.isLoading} {...props}>
        <StyledInnerDiv>
          <Icon src={props.src} />
        </StyledInnerDiv>
      </StyledDiv>
    </>
  );
});
const StyledDiv = styled.div<Props>`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isLoading ? 'block' : 'none')};
  position: fixed;
  z-index: ${(props) => (props.isLoading ? '1' : '10000')};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(221, 204, 204, 0.5);
  }
`;
const StyledInnerDiv = styled.div`
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px; /* harlf of height */
  margin-left: -50px; /* half of width */
`;
