import React from 'react';
import { BoxBg } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { FlexBox } from 'components/atoms/FlexBox';
import { SectionTitle } from 'components/atoms/Heading';
import { CloseIcon } from 'components/atoms/Icon';
import { Right } from 'components/atoms/Right';
import { Spacer } from 'components/atoms/Spacer';

type Props = {
  onClick: () => void;
  onDismiss?: () => void;
};

export const LendingConfirmModal: React.FC<Props> = React.memo(({ onClick, onDismiss }) => {
  return (
    <BoxBg>
      <div
        onClick={() => {
          if (onDismiss) onDismiss();
        }}
      >
        <FlexBox>
          <Right>
            <CloseIcon />
          </Right>
        </FlexBox>
      </div>
      <Spacer size="sm" />
      <SectionTitle>
        レンディングアプリケーションへの
        <br />
        お申込みを行いますか？
      </SectionTitle>
      <Spacer size="lg" />
      <Button
        onClick={() => {
          if (onDismiss) onDismiss();
          onClick();
        }}
      >
        行う
      </Button>
    </BoxBg>
  );
});
