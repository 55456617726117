import React from 'react';
import { styled } from 'twin.macro';

export const Right: React.FC = React.memo(({ children }) => {
  return <StyledDiv>{children}</StyledDiv>;
});

const StyledDiv = styled.div`
  margin-left: auto;
`;
