import { BigNumber as BN } from 'bignumber.js';
import BluePromise from 'bluebird';
import { Contract } from 'web3-eth-contract';
import Fscc from './fscc';
import { toDate } from 'utils';
import { toNumber } from 'utils/number';

export type StakingConfigList = {
  startTimestamp: Date[];
  termInterval: Date;
};

export const defaultStakingConfigList = (): StakingConfigList => {
  return {
    startTimestamp: [],
    termInterval: new Date(0),
  };
};

export type StakingTokenInfoList = {
  latestTermList: number[];
  totalRemainingRewardsList: BN[];
  currentTermRewardsList: BN[];
  nextTermRewardsList: BN[];
  currentStakingList: BN[];
  nextTermStakingList: BN[];
};

export const defaultStakingTokenInfoList = (): StakingTokenInfoList => {
  return {
    latestTermList: [],
    totalRemainingRewardsList: [],
    currentTermRewardsList: [],
    nextTermRewardsList: [],
    currentStakingList: [],
    nextTermStakingList: [],
  };
};

export type StakingTermInfoList = {
  term: number;
  stakeAddList: BN[];
  stakeSumList: BN[];
  rewardSumList: BN[];
};

export const defaultTermInfoList = (): StakingTermInfoList => {
  return {
    term: 0,
    stakeAddList: [],
    stakeSumList: [],
    rewardSumList: [],
  };
};

export type StakingAccountInfoList = {
  userTermList: number[];
  stakeAmountList: BN[];
  nextAddedStakeAmountList: BN[];
  currentTermUserRewardsList: BN[];
  nextTermUserRewardsList: BN[];
  withdrawableStakingAmountList: BN[];
  receivableRewardList: BN[];
};

export type StakingAccountState = {
  receivableReward: BN;
} & StakingAccountInfoList;

export const defaultStakingAccountInfoList = (): StakingAccountInfoList => {
  return {
    userTermList: [],
    stakeAmountList: [],
    nextAddedStakeAmountList: [],
    currentTermUserRewardsList: [],
    nextTermUserRewardsList: [],
    withdrawableStakingAmountList: [],
    receivableRewardList: [],
  };
};

export interface StakingAggregatorInterface {
  address: string;
  contract: Contract;
  _fscc: Fscc;
  _toBNWithToken: (amount: string) => Promise<BN>;
  _fromBNWithToken: (amount: BN) => Promise<string>;
  stake: (amount: BN) => Promise<any>;
  withdraw: (amount: BN) => Promise<any>;
  receiveReward: () => Promise<any>;
  getReceiveReward: (userAddress: string) => Promise<BN>;
  getStakingTokenAddress: () => Promise<string>;
  getStakingContracts: () => Promise<string[]>;
  getConfigs: () => Promise<StakingConfigList>;
  getTokenInfo: () => Promise<StakingTokenInfoList>;
  getTermInfo: (term: number) => Promise<StakingTermInfoList>;
  getAccountInfo: (account: string) => Promise<StakingAccountInfoList>;
}

// Type
export default class StakingAggregator implements StakingAggregatorInterface {
  public address: string;
  public contract: Contract;
  public _fscc: Fscc;

  constructor(address: string, contract: Contract, fscc: Fscc) {
    this.address = address;
    this.contract = contract;
    this._fscc = fscc;
  }

  /* ==== Wrap methods ==== */
  _toBNWithToken = async (amount: string): Promise<BN> => {
    const result = (await this._fscc?.toBN(amount)) || new BN(0);

    return result.isNaN() ? new BN(0) : result;
  };

  _fromBNWithToken = async (amount: BN): Promise<string> => {
    return (await this._fscc.fromBN(amount)) || '0';
  };

  /* ==== Transaction ==== */
  stake = async (amount: BN) => {
    return this.contract.methods.stake(await this._fscc.fromBN(amount));
  };

  withdraw = async (amount: BN) => {
    return this.contract.methods.withdraw(await this._fscc.fromBN(amount));
  };

  receiveReward = async () => {
    return await this.contract.methods.receiveReward();
  };

  /* ==== Call function ==== */
  getReceiveReward = async (userAddress: string): Promise<BN> => {
    return this._toBNWithToken(
      await this.contract.methods.receiveReward().call({ from: userAddress }),
    );
  };

  getStakingTokenAddress = async (): Promise<string> => {
    return await this.contract.methods.getStakingTokenAddress().call();
  };

  getStakingContracts = async (): Promise<string[]> => {
    return await this.contract.methods.getStakingContracts().call();
  };

  getConfigs = async (): Promise<StakingConfigList> => {
    const {
      startTimestampList,
      termInterval,
    } = (await this.contract.methods.getConfigs().call()) as {
      startTimestampList: string[];
      termInterval: string;
    };

    return {
      startTimestamp: startTimestampList.map((startTimestamp) => toDate(startTimestamp)),
      termInterval: toDate(termInterval),
    } as StakingConfigList;
  };

  getTokenInfo = async (): Promise<StakingTokenInfoList> => {
    const {
      latestTermList,
      totalRemainingRewardsList,
      currentTermRewardsList,
      nextTermRewardsList,
      currentStakingList,
      nextTermStakingList,
    } = (await this.contract.methods.getTokenInfo().call()) as {
      latestTermList: string[];
      totalRemainingRewardsList: string[];
      currentTermRewardsList: string[];
      nextTermRewardsList: string[];
      currentStakingList: string[];
      nextTermStakingList: string[];
    };

    return {
      latestTermList: latestTermList.map((latestTerm) => toNumber(latestTerm)),
      totalRemainingRewardsList: await BluePromise.map(
        totalRemainingRewardsList,
        async (totalRemainingRewards) => await this._toBNWithToken(totalRemainingRewards),
      ),
      currentTermRewardsList: await BluePromise.map(
        currentTermRewardsList,
        async (currentReward) => await this._toBNWithToken(currentReward),
      ),
      nextTermRewardsList: await BluePromise.map(
        nextTermRewardsList,
        async (nextTermRewards) => await this._toBNWithToken(nextTermRewards),
      ),
      currentStakingList: await BluePromise.map(
        currentStakingList,
        async (currentStaking) => await this._fscc.toBN(currentStaking),
      ),
      nextTermStakingList: await BluePromise.map(
        nextTermStakingList,
        async (nextTermStaking) => await this._fscc.toBN(nextTermStaking),
      ),
    } as StakingTokenInfoList;
  };

  getTermInfo = async (term: number): Promise<StakingTermInfoList> => {
    const { stakeAddList, stakeSumList, rewardSumList } = (await this.contract.methods
      .getTermInfo(term)
      .call()) as {
      stakeAddList: string[];
      stakeSumList: string[];
      rewardSumList: string[];
    };

    return {
      term,
      stakeAddList: await BluePromise.map(
        stakeAddList,
        async (stakeAdd) => await this._fscc.toBN(stakeAdd),
      ),
      stakeSumList: await BluePromise.map(
        stakeSumList,
        async (stakeSum) => await this._fscc.toBN(stakeSum),
      ),
      rewardSumList: await BluePromise.map(
        rewardSumList,
        async (rewardSum) => await this._fscc.toBN(rewardSum),
      ),
    } as StakingTermInfoList;
  };

  getAccountInfo = async (account: string): Promise<StakingAccountInfoList> => {
    const {
      userTermList,
      stakeAmountList,
      nextAddedStakeAmountList,
      currentTermUserRewardsList,
      nextTermUserRewardsList,
      withdrawableStakingAmountList,
    } = (await this.contract.methods.getAccountInfo(account).call()) as {
      userTermList: string[];
      stakeAmountList: string[];
      nextAddedStakeAmountList: string[];
      currentTermUserRewardsList: string[];
      nextTermUserRewardsList: string[];
      withdrawableStakingAmountList: string[];
    };

    return {
      userTermList: userTermList.map((userTerm) => toNumber(userTerm)),
      stakeAmountList: await BluePromise.map(
        stakeAmountList,
        async (stakeAmount) => await this._fscc.toBN(stakeAmount),
      ),
      nextAddedStakeAmountList: await BluePromise.map(
        nextAddedStakeAmountList,
        async (nextAddedStakeAmount) => await this._fscc.toBN(nextAddedStakeAmount),
      ),
      currentTermUserRewardsList: await BluePromise.map(
        currentTermUserRewardsList,
        async (currentTermUserReward) => await this._toBNWithToken(currentTermUserReward),
      ),
      nextTermUserRewardsList: await BluePromise.map(
        nextTermUserRewardsList,
        async (nextTermUserReward) => await this._toBNWithToken(nextTermUserReward),
      ),
      withdrawableStakingAmountList: await BluePromise.map(
        withdrawableStakingAmountList,
        async (withdrawableStakingAmount) => await this._fscc.toBN(withdrawableStakingAmount),
      ),
    } as StakingAccountInfoList;
  };
}
