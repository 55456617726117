import { BigNumber as BN } from 'bignumber.js';
import React from 'react';
import styled from 'styled-components';
import { FlexBox } from '../FlexBox';

type Props = {
  value: string | BN | undefined;
  unit?: string;
  position?: 'center' | 'right' | 'left';
};

export const Value: React.FC<Props> = React.memo((props) => {
  return (
    <StyledFlexBox position={props.position || 'right'}>
      <StyledValue>{props.value?.toString()}</StyledValue>
      {props.unit ? <StyledUnit>{props.unit}</StyledUnit> : <></>}
    </StyledFlexBox>
  );
});

const StyledValue = styled.span`
  margin-top: 8px;
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
`;

const StyledUnit = styled.span`
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
`;

const StyledFlexBox = styled(FlexBox)<{
  position?: 'center' | 'right' | 'left';
}>`
  ${({ position }) => (position === 'right' ? 'margin-left: auto' : '')};
  ${({ position }) => (position === 'left' ? 'margin-right: auto' : '')};
  ${({ position }) => (position === 'center' ? 'margin: 0 auto;' : '')}
`;
