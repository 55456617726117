import React from 'react';
import { IdWrap, Props } from './idWrap';
import { Wrap } from './wrap';
import { TopWrap } from './wrap/TopWrap';

export const Layout: React.FC<Props> = React.memo((props) => {
  const { id, children } = props;

  return (
    <IdWrap id={id}>
      {id == 'index' || id == 'landing-page' ? (
        <TopWrap>{children}</TopWrap>
      ) : (
        <Wrap>{children}</Wrap>
      )}
    </IdWrap>
  );
});
