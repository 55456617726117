import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { CancelLendingCompleteModal } from './CancelLendingCompleteModal';
import { Button } from 'components/atoms/Buttons';
import { CancelButton } from 'components/atoms/Buttons/CancelButton';
import { Center } from 'components/atoms/Center';
import { FlexBox } from 'components/atoms/FlexBox';
import { SectionTitle } from 'components/atoms/Heading';
import { CloseIcon } from 'components/atoms/Icon';
import { Right } from 'components/atoms/Right';
import { Spacer } from 'components/atoms/Spacer';
import { TextRed } from 'components/atoms/TextRed';
import { setCloseIndicate, setLoading, setNoUpdateButtonFlg } from 'features/handover';
import { RootState } from 'features/reducer';
import { useModal } from 'hooks';
import { useStaking } from 'hooks/staking/useStaking';

type Props = {
  onClick?: () => void;
  onDismiss?: () => void;
};

export const CancelLendingConfirmModal: React.FC<Props> = React.memo(({ onDismiss }) => {
  const closeIndicate = useSelector((state: RootState) => state.handover.closeIndicate);
  const staking = useStaking();
  const dispatch = useDispatch();
  const [openModal, _] = useModal(<CancelLendingCompleteModal />);

  useEffect(() => {
    if (closeIndicate) {
      dispatch(setCloseIndicate(false));
      if (onDismiss) onDismiss();
    }
  }, [closeIndicate, dispatch, onDismiss]);

  return (
    <>
      <StyledBgDiv>
        <div
          onClick={() => {
            if (onDismiss) onDismiss();
          }}
        >
          <FlexBox>
            <Right>
              <CloseIcon />
            </Right>
          </FlexBox>
        </div>
        <SectionTitle>確認</SectionTitle>
        <Spacer size="sm" />
        <Center>
          全ての貸出が継続されないことになりますが
          <br />
          よろしいでしょうか。
        </Center>
        <Spacer size="sm" />
        <Center>
          <TextRed>
            「貸出を継続しない」ボタンを押されますと
            <br />
            キャンセルはできません。
          </TextRed>
        </Center>
        <Spacer size="sm" />
        <Center>
          <TextRed>
            貸借数及び貸借料は貸出状況画面より満期日をご確認の上、
            <StyledBr />
            満期後にお受取りください。
          </TextRed>
        </Center>
        <Spacer size="sm" />
        <Center>
          お申込期間中に貸出をキャンセルする場合は、
          <br />
          即時に貸借数が返却されます。
        </Center>
        <Spacer size="md" />
        <Button
          onClick={() => {
            if (onDismiss) onDismiss();
          }}
        >
          キャンセル
        </Button>
        <Spacer size="md" />
        <CancelButton
          onClick={() => {
            dispatch(setLoading(true));
            staking
              .withdraw(staking.withdrawableStakingAmount)
              .then(() => {
                dispatch(setNoUpdateButtonFlg(true));
              })
              .then(() => {
                openModal();
              })
              .catch(() => {
                if (onDismiss) onDismiss();
              })
              .finally(() => {
                dispatch(setLoading(false));
              });
          }}
        >
          貸出を継続しない
        </CancelButton>
      </StyledBgDiv>
    </>
  );
});

const StyledBr = styled.br`
  display: block;

  @media (max-width: 959px) {
    display: none;
  }
`;
export const StyledBgDiv = styled.div`
  background-color: ${(props) => props.theme.color.grey[800]};
  padding: 16px;

  @media (max-width: 959px) {
    margin-right: 12px;
    margin-left: 12px;
  }
`;
