import React from 'react';
import { styled } from 'twin.macro';

export const BoxBg: React.FC = React.memo((props) => {
  const { children } = props;

  return <StyledBgDiv>{children}</StyledBgDiv>;
});

export const StyledBgDiv = styled.div`
  //area-bg
  background-color: ${(props) => props.theme.color.grey[800]};
  padding: 16px;
`;
