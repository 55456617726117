import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Anchor } from 'components/atoms/Anchor';
import { Box } from 'components/atoms/Box';
import { Button } from 'components/atoms/Buttons';
import { HeaderTitle } from 'components/atoms/Heading';
import { Meter } from 'components/atoms/Meter';
import { Spacer } from 'components/atoms/Spacer';
import { Layout } from 'components/layouts';
import { RootState } from 'features/reducer';
import { useFetch } from 'hooks/staking/useFetchStaking';
import { useStaking } from 'hooks/staking/useStaking';
import { createNextTermMaturityDate } from 'utils/date';

export const Applied: React.FC = React.memo(() => {
  const staking = useStaking();
  const fetch = useFetch();
  const cycle = staking.cycle;
  const startTimestampList = fetch.config?.startTimestamp ? fetch.config?.startTimestamp : [];

  const [datetime, setDatetime] = useState<string>('');
  useEffect(() => {
    const fetchDateTime = async () => {
      const result = await axios.head('https://fiscocoin.com/lending');
      // const result = await axios.head('https://test-lending.f5.si/lending');
      // const result = await axios.head('http://localhost:3000/lending');
      setDatetime(result.headers.date);
    };
    fetchDateTime();
  }, []);

  let displayDateTime = '';

  if (startTimestampList.length > 0 && cycle > 0) {
    const nextStartDate = createNextTermMaturityDate(
      cycle,
      startTimestampList[0],
      new Date(datetime),
    );

    if (nextStartDate.getFullYear() !== NaN) {
      displayDateTime =
        nextStartDate.getFullYear().toString() +
        '/' +
        ('00' + (nextStartDate.getMonth() + 1).toString()).slice(-2) +
        '/' +
        ('00' + nextStartDate.getDate().toString()).slice(-2);
    }
  }
  const expectedReward = useSelector((state: RootState) => state.handover.expectedReward);

  return (
    <Layout id="applied">
      <Spacer size="md" />
      <HeaderTitle>お申込み完了</HeaderTitle>
      <Spacer size="md" />
      <Meter step="applied" />
      <Spacer size="sm" />
      <Box applied>
        <Spacer size="sm" />
        <StyledDiv>お申込みいただきありがとうございました！</StyledDiv>
        <Spacer size="sm" />
        <StyledDiv>処理が完了致しました！</StyledDiv>
        <Spacer size="md" />
        <StyledDiv>
          お受け取りいただける貸借料の予定は <StyledSpan>{expectedReward}</StyledSpan> 枚
        </StyledDiv>
        <Spacer size="sm" />
        <StyledDiv>
          満期日は <StyledSpan>{displayDateTime}</StyledSpan> となります。
        </StyledDiv>
        <Spacer size="sm" />
        <StyledDiv>貸出状況につきましてはマイページよりご確認いただけます。</StyledDiv>
        <Spacer size="sm" />
        <Spacer size="sm" />
        <Anchor to="/lending/mypage">
          <Button>次へ</Button>
        </Anchor>
        <Spacer size="sm" />
      </Box>
    </Layout>
  );
});

const StyledDiv = styled.div`
  text-align: center;
  font-weight: 600;
`;

const StyledSpan = styled.span`
  color: red;
`;
