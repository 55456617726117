import React from 'react';
import { styled } from 'twin.macro';

type Props = {
  step: 'form' | 'applying' | 'applied';
};

export const Meter: React.FC<Props> = React.memo(({ step }) => {
  return (
    <>
      <StyledUl>
        <li className={step === 'form' ? 'stepbar-visited' : ''}>
          申込み内容
          <br />
          <span></span>
        </li>
        <li className={step === 'applying' ? 'stepbar-visited' : ''}>
          確認
          <br />
          <span></span>
        </li>
        <li className={step === 'applied' ? 'stepbar-visited' : ''}>
          申込み完了
          <br />
          <span></span>
        </li>
      </StyledUl>
      <Padding size={12} />
    </>
  );
});

const Padding = styled.div<{
  size: number;
}>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
`;

const StyledUl = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 400px;
  position: relative;
  margin: 20px auto;
  text-align: center;

  li {
    font-size: 12px;
    list-style: none;
    position: relative;
    width: 33.333%;
  }

  li:after {
    background: ${(props) => props.theme.color.lightBlue};
    content: '';
    width: calc(100% - 32px);
    height: 4px;
    position: absolute;
    left: calc(-50% + 16px);
    bottom: 26px;
  }

  li:first-child:after {
    display: none;
  }

  li span {
    background: ${(props) => props.theme.color.lightBlue};
    color: ${(props) => props.theme.color.white};
    display: inline-block;
    height: 32px;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  .stepbar-visited:after {
    background: ${(props) => props.theme.color.darkBlue};
  }

  .stepbar-visited span {
    background: ${(props) => props.theme.color.darkBlue};
  }
`;
