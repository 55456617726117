import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from 'twin.macro';
import { modalSlice } from 'features/modal';
import { RootState } from 'features/reducer';

export const ModalProvider: React.FC = React.memo(({ children }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.modal.isOpen);
  const content = useSelector((state: RootState) => state.modal.content);
  const { onDismiss } = modalSlice.actions;

  return (
    <>
      {children}
      {isOpen && (
        <StyledModalWrapper>
          <StyledModalBackdrop
            onClick={() => {
              dispatch(onDismiss());
            }}
          />
          <StyledDiv>
            {React.isValidElement(content) &&
              React.cloneElement(content, {
                onDismiss: () => {
                  dispatch(onDismiss());
                },
              })}
          </StyledDiv>
        </StyledModalWrapper>
      )}
    </>
  );
});

const StyledModalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const StyledModalBackdrop = styled.div`
  background-color: ${(props) => props.theme.color.backdrop};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

const StyledDiv = styled.div`
  z-index: ${(props) => props.theme.zIndex.modal};
`;
