import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Value } from 'components/atoms/Value';
import { useFetch } from 'hooks/staking/useFetchStaking';
import { useStaking } from 'hooks/staking/useStaking';
import { createNextTermMaturityDate } from 'utils/date';

export const ContractMaturityLabel: React.FC = React.memo(() => {
  const staking = useStaking();
  const fetch = useFetch();
  const cycle = staking.cycle;
  const startTimestampList = fetch.config?.startTimestamp ? fetch.config?.startTimestamp : [];

  const [datetime, setDatetime] = useState<string>('');
  useEffect(() => {
    const fetchDateTime = async () => {
      const result = await axios.head('https://fiscocoin.com/lending');
      // const result = await axios.head('https://test-lending.f5.si/lending');
      // const result = await axios.head('http://localhost:3000/lending');
      setDatetime(result.headers.date);
    };
    fetchDateTime();
  }, []);

  let displayDateTime = '';

  if (startTimestampList.length > 0 && cycle > 0) {
    const nextStartDate = createNextTermMaturityDate(
      cycle,
      startTimestampList[0],
      new Date(datetime),
    );

    if (nextStartDate.getFullYear() !== NaN) {
      displayDateTime =
        nextStartDate.getFullYear().toString() +
        '/' +
        ('00' + (nextStartDate.getMonth() + 1).toString()).slice(-2) +
        '/' +
        ('00' + nextStartDate.getDate().toString()).slice(-2);
    }
  }

  return <Value value={displayDateTime} position={'right'} />;
});
