import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useWallet } from 'use-wallet';

type Props = {
  onClick: () => void;
};

export const WalletConnectButton: React.FC<Props> = React.memo(({ onClick }) => {
  const connect = useWallet().connect as (chain: string) => Promise<void>;
  const status = useWallet().status;
  const reset = useWallet().reset;
  const [clicked, setClicked] = useState<boolean>(false);
  const history = useHistory();

  // console.log('ethereum', ethereum, window.ethereum);

  const handleClick = async () => {
    try {
      if (!window.ethereum) {
        reset();
        history.push('/lending/no-environment');
      }
      if (status !== 'connected') {
        await connect('injected');
      }
      setClicked(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (clicked && status === 'connected') onClick();
  }, [clicked, status]); // eslint-disable-line

  return <StyledButton onClick={handleClick}>スタート</StyledButton>;
});

const StyledButton = styled.button`
  font-size: 18px;
  font-weight: 600;
  line-height: calc(48px - 2px);
  height: 48px;
  width: 300px;
  border-radius: 20px;
  border: 1px solid #0069c0;
  background-color: #0069c0;
  color: #fff;
  display: inline-block;
  text-align: center;
  padding: 0;
  position: relative;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  z-index: 1;
  transition: 0.2s linear;

  &:hover {
    color: #0069c0;
    border: 1px solid #0069c0;
    background-color: #fff;
    cursor: pointer;
  }

  &:active,
  &:focus {
    box-shadow: none;
    box-shadow: 1px 1px 2px #424242 inset;
    background-color: #fff;
    color: #0069c0;
    border: 1px solid #0069c0;
    outline: none;
  }

  &active::after,
  &:focus::after {
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }

  &:disabled {
    background-color: #bdbdbd;
    color: #616161;
    border-color: #bdbdbd;
    box-shadow: none;
    opacity: 1;
    cursor: inherit;
  }
`;
