import { BigNumber as BN } from 'bignumber.js';

export const loremIpsumDolor = 'Lorem ipsum dolor sit amet...';
export const MAX_UINT256 = new BN(2).pow(new BN(256)).minus(new BN(1));
export const zeroAddress = '0x0000000000000000000000000000000000000000';
export const ETHIcon =
  'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png';
export const defaultRefreshInterval = 15000;
export const longRefreshInterval = 30000;
export const daysPerYear = 365.25;
