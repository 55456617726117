import { BigNumber as BN } from 'bignumber.js';

export const addEx = (raw: BN, x: number): string => {
  const Ex = new BN(raw).times(`1e${x}`).dp(0).toString(10);

  return Ex;
};
export const removeEx = (Ex: string | BN, x: number): BN => {
  const raw = new BN(Ex).div(`1e${x}`);

  return raw;
};
