import React from 'react';
import { styled } from 'twin.macro';

export const TopWrap: React.FC = React.memo((props) => {
  const { children } = props;

  return <StyledWrap className="drawer drawer--right"> {children}</StyledWrap>;
});

const StyledWrap = styled.div`
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 959px) {
    width: 100%;
  }
`;
