import { BigNumber as BN } from 'bignumber.js';
import Web3 from 'web3';
import { TransactionReceipt } from 'web3-core';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';
import Fscc from './fscc';
import StakingAggregator from './stakingAggregator';
import { zeroAddress } from 'resource';
import { addEx, sleep } from 'utils';

export interface FiscoInterface {
  web3: Web3;
  networkId: number;
  sender: string;
  stakingAggregator: StakingAggregator;
  fscc: Fscc;
}

type ContractProps = {
  abi: AbiItem[] | AbiItem;
  address: string;
};

export const newContract = (
  web3: Web3,
  abi: AbiItem[] | AbiItem,
  contractAddress: string,
): Contract => {
  return new web3.eth.Contract(abi, contractAddress);
};
export default class Fisco implements FiscoInterface {
  public web3: Web3;
  public networkId: number;
  public sender: string;
  public fscc: Fscc;
  public stakingAggregator: StakingAggregator;

  constructor(
    provider: any,
    networkId: number,
    sender: string,
    fscc: ContractProps,
    stakingAggregator: ContractProps,
  ) {
    // web3
    let ethProvider;
    if (typeof provider === 'string') {
      // e.g. infura
      if (provider.includes('wss')) {
        ethProvider = new Web3.providers.WebsocketProvider(provider, {
          timeout: 10000,
        });
      } else {
        ethProvider = new Web3.providers.HttpProvider(provider, {
          timeout: 10000,
        });
      }
    } else {
      ethProvider = provider;
    }

    this.networkId = networkId;
    this.web3 = new Web3(ethProvider);
    this.sender = sender;

    // FSCC
    this.fscc = new Fscc(fscc.address, newContract(this.web3, fscc.abi, fscc.address));
    this.stakingAggregator = new StakingAggregator(
      stakingAggregator.address,
      newContract(this.web3, stakingAggregator.abi, stakingAggregator.address),
      this.fscc,
    );
  }

  // web3 methods
  getDefaultAccount = (): string => {
    return this.web3.eth.defaultAccount || zeroAddress;
  };

  maximumGas = (): number => {
    return 1500000;
  };

  estimateGas = async (funcData: any, value?: string): Promise<number> => {
    let gasLimit = this.maximumGas();
    try {
      gasLimit = (await funcData.estimateGas({
        from: this.sender,
        gas: this.maximumGas(),
        value,
      })) as number;
    } catch (error) {
      console.info('sendTx', error);
      gasLimit = this.maximumGas();
    }

    return Math.min(gasLimit, this.maximumGas());
  };

  send = async (
    funcData: any,
    option?: {
      value?: BN;
      onTxHash?: (txHash: string, receipt?: TransactionReceipt, error?: any) => void;
    },
  ): Promise<boolean> => {
    try {
      return funcData.send(
        {
          from: this.sender,
          gas: await this.estimateGas(
            funcData,
            option?.value ? addEx(option.value, 18) : undefined,
          ),
          value: option?.value ? addEx(option.value, 18) : undefined,
        },
        async (error: any, txHash: string) => {
          if (error) {
            console.log('error', error);
            option?.onTxHash && option?.onTxHash('', undefined, error);

            return false;
          }
          const receipt = await this.waitTransaction(txHash);
          if (option?.onTxHash) {
            option?.onTxHash(txHash, receipt);
          }
          if (!status) {
            console.log('transaction failed');

            return false;
          }

          return true;
        },
      );
    } catch (e) {
      console.error(e);

      return false;
    }
  };

  waitTransaction = async (txHash: string): Promise<TransactionReceipt> => {
    let txReceipt: TransactionReceipt | null = null;
    while (txReceipt === null) {
      const r = await this.web3.eth.getTransactionReceipt(txHash);
      txReceipt = r;
      await sleep(2000);
    }

    return txReceipt;
  };
}
